import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Tag,
  message,
  Divider,
  Popconfirm,
  notification,
} from "antd";
import {
  ApiOutlined,
  DisconnectOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useAuth } from "../../../../contexts/AuthContext.js";
import QbConfigure from "../Integrations/Quickbooks/Configure.jsx";
import XeroConfigure from "../Integrations/Xero/Configure.jsx";
import AdpConfigure from "../Integrations/Adp/Configure.jsx";
import { Link, useNavigate } from "react-router-dom";
import apiCall from "../../../../utils/functions/apiCall.js";

const IntegrationTiles = ({ integrations, setActiveKey }) => {
  const { user } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const handleButtonClick = async (integration) => {
  //   setCurrentIntegration(integration);

  //   // will trigger use effect
  // };

  // // useEffect(() => {
  // //   if (currentIntegration) {
  // //     handleConnect(currentIntegration);
  // //   }
  // // }, [currentIntegration]);

  const handleModalClose = () => {
    setIsModalVisible(false);
    setCurrentIntegration(null);
  };
  const handleConnect = async (integrationValue) => {
    if (!integrationValue || !integrationValue.connectUrl) return;

    setLoading(true);
    try {
      const { data } = await axios.get(
        `${integrationValue.connectUrl}?accessToken=${user.accessToken}`
      );

      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error("No URL returned from backend");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // If response is 401 Unauthorized, navigate to logout
        notification.warning({
          message: "Session Expired",
          description: "Your session has expired. Please log in again.",
        });
      } else {
        message.error("Failed to connect. Please try again later.");
        console.error("Connection error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDisconnect = async (integrationValue) => {
    setLoading(true);
    try {
      await axios.delete(
        `${integrationValue.disconnectUrl}?accessToken=${user.accessToken}`
      );
      // Reload page
      window.open('https://simple-checks.com/quickbooks-disconnected', '_blank', 'noopener,noreferrer');

      window.location.href = '/settings/integrations?disconnected=true&integration=quickbooks';
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // If response is 401 Unauthorized, navigate to logout
        notification.warning({
          message: "Session Expired",
          description: "Your session has expired. Please log in again.",
        });
      } else {
        message.error("Failed to revoke access. Please try again later.");
        console.error("Connection error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  function handleSaveConfig() {
    // Save config
    // console.log("Save config");
    handleModalClose();
  }

  function modalHandler(currentIntegrationName, currentIntegration) {
    if (currentIntegrationName === "quickbooks") {
      return (
        <QbConfigure
          currentIntegration={currentIntegration}
          user={user}
          handleSaveConfig={handleSaveConfig}
        />
      );
    } else if (currentIntegrationName === "xero") {
      return (
        <XeroConfigure
          currentIntegration={currentIntegration}
          user={user}
          handleSaveConfig={handleSaveConfig}
        />
      );
    } else if (currentIntegrationName === "adp") {
      return (
        <AdpConfigure
          currentIntegration={currentIntegration}
          user={user}
          handleSaveConfig={handleSaveConfig}
        />
      );
    } else {
      return <div>Integration not found</div>;
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      <Row gutter={[16, 16]}>
        {integrations.map((integration) => (
          <Col xs={24} sm={12} md={8} lg={6} key={integration.name}>
            <Card
              hoverable
              style={{
                textAlign: "center",
                border: integration.connected
                  ? "2px solid #52c41a" // Green border if connected
                  : "1px solid #d9d9d9",
                borderRadius: "8px",
              }}
              cover={
                <div
                  style={{
                    height: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f9f9f9",
                    borderBottom: "1px solid #e8e8e8",
                  }}
                >
                  <img
                    alt={integration.title}
                    src={integration.image}
                    style={{ maxWidth: "70%", maxHeight: "70%" }}
                  />
                </div>
              }
            >
              {integration.connected ? (
                <Tag color="green">Connected</Tag>
              ) : (
                <Tag color="default">Not Connected</Tag>
              )}
              <h3 style={{ margin: "10px 0" }}>{integration.title}</h3>
              <div style={{ textAlign: "left" }}>
                <div style={{ margin: "10px 0" }}>
                  {integration.description}
                </div>
              </div>

              {user.isPremium ? (
                // If it's QuickBooks, render the image or disconnect button based on connection status
                integration.name === "quickbooks" ? (
                  integration.connected ? (
                    // Show "Disconnect" button if connected
                    <Link
                      className=""
                      disabled={loading}
                      loading={loading}
                      onClick={() => handleDisconnect(integration)}
                    >
                      Disconnect
                    </Link>
                  ) : (
                    // Show the image if not connected
                    <img
                      src={integration.buttonImage}
                      alt={integration.title}
                      style={{
                        borderRadius: "6px",
                        cursor: "pointer",
                        width: "auto",
                        height: "auto",
                      }}
                      onClick={() => handleConnect(integration)} // Handle connection on click
                      onMouseOver={(e) =>
                        (e.currentTarget.src = integration.buttonImageHover)
                      } // Hover effect
                      onMouseOut={(e) =>
                        (e.currentTarget.src = integration.buttonImage)
                      } // Revert back on mouse out
                    />
                  )
                ) : (
                  // For other integrations, show the modal connection button
                  <Button
                    className={
                      integration.connected ? "button-green" : "button-gray"
                    }
                    onClick={() => handleConnect(integration)}
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      marginTop: "10px",
                    }}
                    disabled={!integration.enabled}
                  >
                    {integration.connected ? (
                      <>
                        <SettingOutlined /> Settings
                      </>
                    ) : !integration.enabled ? (
                      integration.disabledButtonMessage // If disabled, show message
                    ) : (
                      <>
                        <ApiOutlined /> Connect
                      </>
                    )}
                  </Button>
                )
              ) : (
                <Popconfirm
                  title="This feature is available to Premium subscriptions"
                  onConfirm={() => setActiveKey("2")}
                  okText="Review Subscription"
                >
                  <Button
                    className="button-gray"
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      marginTop: "10px",
                    }}
                  >
                    <ApiOutlined /> Connect
                  </Button>
                </Popconfirm>
              )}
            </Card>
          </Col>
        ))}
      </Row>

      {currentIntegration && (
        <Modal
          title={`Integration: ${currentIntegration.title}`}
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          {!currentIntegration.connected ? (
            <>
              <p>You are not yet connected to {currentIntegration.title}.</p>
              <Divider />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleModalClose}>Cancel</Button>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    handleConnect(currentIntegration);
                  }}
                >
                  <ApiOutlined /> Connect Now
                </Button>
              </div>
            </>
          ) : (
            <>
              <p>You are connected to {currentIntegration.title}.</p>
              <Divider />
              {modalHandler(currentIntegration.name)}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleModalClose}>Cancel</Button>

                <Popconfirm
                  title="Are you sure you want to revoke access? Simple Checks will no longer be able sync data with this application."
                  onConfirm={handleDisconnect} // Executes when the user clicks "Yes"
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="button-red" loading={loading}>
                    <DisconnectOutlined /> Revoke Access
                  </Button>
                </Popconfirm>
                <Button className="button-blue" onClick={handleSaveConfig}>
                  Save
                </Button>
              </div>
            </>
          )}
        </Modal>
      )}
    </div>
  );
};

export default IntegrationTiles;
