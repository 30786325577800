import { Card, Typography, Button, Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import Pricing from './index';
import { useAuth } from '../../contexts/AuthContext';

function PricingSubscriptionPage() {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {

    }, [user]);


    //TODO: Add pricing page

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img className="shadow" src={'/images/logo/logo_with_background.svg'} alt="logo" style={{ width: "500px",marginTop:20, borderRadius: "10px" }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
            <p>In order to proceed, please select one of the plans below:</p>
            </div>
            <Row style={{ width: "100%" }} >
                <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh", marginLeft: "-75px" }}>
                    <Pricing />
                </Col>
            </Row>
        </>)
}

export default PricingSubscriptionPage