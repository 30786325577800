export const checkStock = {
  title: "Check Stock Paper (Preferred)",
  templateId: "checkStock", //never change this
  createdAt: new Date(),
  updatedAt: new Date(),
  enabled: true,
  isDeleted: false,
  options: {
    printDottedLine: false,
    printCheckNumber: true,
    printInstitutionName: true,
    printOwner: true,
    printRoutingAndAccount: true,
    printSignatureIfAvailable: true,
    printSignatureLine: true,
    printLabels: true,
    printInstructions: false,
    printBorderAroundCheck: false,
    printOfficialCheck: false,
    printAccountMask: true,
    printLogo: true,
  },

  coordinates: {
    layout: {
      signatureLine: {
        y: 175,
        x: 395,
      },
      logo: {
        x: 10,
        y: 15,
      },
      authorizedSignerLabel: {
        y: 177,
        x: 450,
      },
      amountLabel: {
        x: 10,
        y: 107,
      },
      mailToLabel: {
        x: 10,
        y: 132,
      },
      payToLabel: [
        {
          x: 10,
          y: 79,
        },
        { x: 120, y: 251 },
        { x: 120, y: 537 },
      ],
      toTheOrderOfLabel: [
        {
          x: 10,
          y: 88,
        },
        { x: 120, y: 260 },
        { x: 120, y: 546 },
      ],
      memoLabel: [
        {
          x:10,
          y:178
        },
        {
          x: 10,
          y: 270,
        },
        {
          x: 10,
          y: 555,
        },
      ],
      dateLabel: [
        {
          x: 470,
          y: 50,
        },
        {
          x: 10,
          y: 253,
        },
        {
          x: 10,
          y: 540,
        },
      ],
      dollarSignLabel: {
        x: 400,
        y: 150,
      },
      payableToLine: {
        x: 400,
        y: 150,
      },
      writtenDollarLine: {},
      instructionsLabel: {
        x: -5,
        y: 485,
      },
      validateLabel: {
        x: -5,
        y: 495,
      },
      fraudLabel: {
        x: -5,
        y: 505,
      },
      officialCheckLabel: {
        x: 510,
        y: 200,
      },
    },
    instructions: {
      x: 58,
      y: 485,
    },
    validate: {
      x: 37,
      y: 495,
    },
    fraud: {
      x: 26,
      y: 505,
    },

    institutionName: {
      x: 350,
      y: 15,
    },
    ownerName: {
      x: 70,
      y: 15,
    },
    ownerAddressLine1: {
      x: 70,
      y: 28,
    },
    ownerAddressLine2: {
      x: 70,
      y: 40,
    },
    mailingInfo: {
      x: 70,
      y: 130,
    },
    micrRoutingAccount: {
      y: 210,
      x: 140,
    },
    signatureImg: {
      y: 150,
      x: 460,
    },
    writtenDollar: {
      y: 105,
      x: 70,
    },
    date: [
      {
        x: 22,
        y: 48,
      },
      {
        x: 480,
        y: 252,
      },
      {
        x: 480,
        y: 538,
      },
    ],
    payableTo: [
      {
        x: 70,
        y: 80,
      },
      {
        x: 180,
        y: 250,
      },
      {
        x: 180,
        y: 536,
      },
    ],
    memo: [
      {
        x: 70,
        y: 176
      },
      {
        x: 50,
        y: 268,
      },
      {
        x: 50,
        y: 553,
      },
    ],
    amount: [
      {
        x: 22,
        y: 80,
      },
      {
        x: 22,
        y: 270,
      },
      {
        x: 22,
        y: 560,
      },
    ],
    accountMask: [
      {
        x: 22,
        y: 465,
      },
      { x: 22, y: 740 },
    ],
    checkNumber: [
      {
        x: 22,
        y: 15,
      },
      {
        x: 22,
        y: 250,
      },
      {
        x: 22,
        y: 540,
      },
    ],
  },
};
