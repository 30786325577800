import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Tooltip,
  Space,
  Badge,
  Popconfirm,
  Tag,
  Spin,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  SendOutlined,
  CloseSquareOutlined,
  EyeOutlined,
  CloudDownloadOutlined,
  HourglassOutlined,
  ApiOutlined,
  CheckCircleOutlined,
  BankOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/functions/dates";
import PrintPreviewModal from "./PrintPreview";
import { decryptAccounts } from "../../../utils/functions/encryption";
import moment from "moment";
import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { checkMetaUpsert } from "../../../services/metadata/checks";

const CheckTable = ({
  checks,
  showModal,
  handleDelete,
  setSelectedChecksToPrint,
  handlePrintConfirm,
  setLastCheckNumber,
  isPDFModalVisible,
  selectedChecksToPrint,
  fetchChecks,
  setIsPdfModalVisible,
  accounts,
  templates,
  user,
  handleVoid,
  setECheckModalOpen,
  eCheckModalOpen,
  setIsPrintModalVisible,
  handleSendEmail,
  startingCheckNumber,
  openECheckModal,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Control loading state
  const [checkNumber, setCheckNumber] = useState();
  const [defaultTemplate, setDefaultTemplate] = useState();
  const [defaultAccount, setDefaultAccount] = useState();
  const [decAccounts, setDecAccounts] = useState([]);
  const hasDecrypted = useRef(false); // <-- Flag to ensure decryption runs only once
  const ALL_STATUS = [
    "draft",
    "to be printed",
    "printed",
    "void",
    "emailed",
    "fulfillment",
  ];
  const STATUS_CAN_EDIT = ["draft", "to be printed", "printed"];
  const STATUS_CAN_DELETE = ["draft", "to be printed", "void"];
  const STATUS_CAN_EMAIL = ["draft", "to be printed"];
  const STATUS_CAN_VOID = ["printed", "emailed"];
  const STATUS_CAN_CLEAR = ["printed", "emailed", "fulfillment"];
  const STATUS_CAN_PRINT = ["draft", "to be printed", "printed", "emailed"];

  useEffect(() => {
    if (user) {
      // Set default template if it exists in user preferences
      if (user?.clientData?.preferences?.defaultTemplate) {
        const matchingTemplate = templates.find(
          (template) =>
            template.id === user.clientData.preferences.defaultTemplate
        );
        setDefaultTemplate(matchingTemplate || null);
      }

      // Set default account if it exists in user preferences
      if (user?.clientData?.preferences?.defaultAccount) {
        const matchingAccount = accounts.find(
          (account) => account.id === user.clientData.preferences.defaultAccount
        );
        setDefaultAccount(matchingAccount || null);
      }

      // Decrypt accounts only once when accounts are first available
      const handleDecryption = async () => {
        if (!hasDecrypted.current && accounts && accounts.length > 0) {
          setLoading(true);
          try {
            const result = await decryptAccounts(accounts);
            setDecAccounts(result);
            hasDecrypted.current = true; // <-- Set flag to true after decryption
          } catch (error) {
            console.error("Error decrypting accounts:", error);
          } finally {
            setLoading(false);
          }
        }
      };

      if (!decAccounts) handleDecryption(); // Call once when accounts are ready
    }
  }, [checks, user, templates, accounts]); // Kept `accounts` to ensure decryption when accounts are available

  async function handleQuickPrint(check) {
    setSelectedChecksToPrint([check]);
    setIsPrintModalVisible(true);
    setLoading(false);
    // setSelectedChecksToPrint([check]);
    // setIsPdfModalVisible(true);
  }

  function formatDateFromFirestore(timestamp) {
    if (!timestamp || typeof timestamp.seconds !== "number") {
      return "Invalid Date";
    }

    const date = new Date(timestamp.seconds * 1000);
    return moment(date).format("MM-DD-YYYY");
  }

  const handleClear = async (checkId) => {
    try {
      const docRef = doc(db, `clients/${user.clientId}/checks`, checkId);

      // Update the substatus array to push 'cleared'
      await updateDoc(docRef, {
        substatus: arrayUnion("cleared"),
      });

      const updatedDoc = await getDoc(docRef);

      await checkMetaUpsert(user, updatedDoc);
      fetchChecks();
      // Optionally, trigger any additional actions after successful update
    } catch (error) {}
  };

  const columns = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space className="quick-action-div">
          {record?.status?.toLowerCase() === "fulfillment" && (
            <Tooltip title="View fulfillment order details">
              <Button
                icon={<EyeOutlined className="info" />}
                type="dashed"
                shape="circle"
                className="quick-print-btn" // Add this class for the tutorial step
                onClick={() =>
                  navigate(
                    `/fulfillment/${record?.fulfillmentMeta?.fulfillmentOrderId}`
                  )
                }
              ></Button>
            </Tooltip>
          )}
          {STATUS_CAN_EDIT.includes(record.status) && (
            <Tooltip title="Edit">
              <Button
                icon={<EditOutlined />}
                type="dashed"
                disabled={record?.account?.id === 1}
                shape="circle"
                onClick={() => showModal(record)}
              ></Button>
            </Tooltip>
          )}

          {STATUS_CAN_PRINT.includes(record?.status) &&
            !record?.substatus?.includes("cleared") &&
            !record?.substatus?.includes("downloaded") && (
              <Tooltip title="Quick Print/Reprint">
                <Button
                  icon={<PrinterOutlined className="info" />}
                  type="dashed"
                  shape="circle"
                  className="quick-print-btn" // Add this class for the tutorial step
                  onClick={() => handleQuickPrint(record)}
                ></Button>
              </Tooltip>
            )}

          {STATUS_CAN_EMAIL.includes(record.status) && (
            <Tooltip title="Email Check">
              <Button
                icon={<SendOutlined className="warning" />}
                type="dashed"
                shape="circle"
                onClick={() => openECheckModal(record)}
              ></Button>
            </Tooltip>
          )}
          {STATUS_CAN_DELETE.includes(record.status) && (
            <Popconfirm
              title="Are you sure you want to delete this check?"
              onConfirm={() => handleDelete(record.id)}
            >
              <Tooltip title="Delete" color="darkRed">
                <Button
                  icon={<DeleteOutlined className="error" />}
                  type="dashed"
                  shape="circle"
                  disabled={
                    record.status === "printed" &&
                    user.role !== "admin" &&
                    user.role !== "employee"
                  }
                ></Button>
              </Tooltip>
            </Popconfirm>
          )}
          {STATUS_CAN_VOID.includes(record.status) && (
            <Popconfirm
              title="Are you sure you want to void the check? If the check was emailed, the recipient will no longer be able to print it after voided."
              onConfirm={() => handleVoid(record.id)}
            >
              <Tooltip title="Void" color="darkOrange">
                <Button
                  icon={<CloseSquareOutlined className="orange" />}
                  type="dashed"
                  shape="circle"
                  disabled={
                    record.status === "printed" &&
                    user.role !== "admin" &&
                    user.role !== "employee"
                  }
                ></Button>
              </Tooltip>
            </Popconfirm>
          )}
          {STATUS_CAN_CLEAR.includes(record.status) &&
            !record?.substatus?.includes("cleared") && (
              <Popconfirm
                title="Are you sure you want to mark the check as cleared?"
                onConfirm={() => handleClear(record.id)} // Handle clear action
              >
                <Tooltip title="Mark check as cleared" color="bg-green-light">
                  <Button
                    icon={<CheckCircleOutlined className="success" />}
                    type="dashed"
                    shape="circle"
                    disabled={
                      record.status === "printed" &&
                      user.role !== "admin" &&
                      user.role !== "employee"
                    }
                  />
                </Tooltip>
              </Popconfirm>
            )}
        </Space>
      ),
    },

    {
      title: "Check Number",
      dataIndex: "checkNumber",
      key: "checkNumber",
      sorter: (a, b) => {
        const numA = parseInt(a.checkNumber, 10);
        const numB = parseInt(b.checkNumber, 10);

        // If both are valid numbers, sort numerically
        if (!isNaN(numA) && !isNaN(numB)) {
          return numA - numB;
        }

        // If one or both are not numbers, sort as strings
        return String(a.checkNumber).localeCompare(String(b.checkNumber));
      },
    },

    {
      title: "Payable To",
      dataIndex: "payableTo",
      key: "payableTo",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 185,
      key: "status",
      render: (status, record) => {
        let classValue;
        switch (status) {
          case "draft":
            classValue = "bg-gray-light gray";
            break;
          case "to be printed":
            classValue = "bg-info-light info";
            break;
          case "printed":
            classValue = "bg-success-light success";
            break;
          case "void":
            classValue = "bg-error-light error";
            break;
          case "emailed":
            classValue = "bg-success-light success";
            break;
          default:
            classValue = "bg-gray-light gray";
            break;
        }

        return (
          <>
            {/* Status tag */}
            <Tag className={classValue}>
              {status.toLowerCase() === "to be printed"
                ? "To Be Printed"
                : status.charAt(0).toUpperCase() + status.slice(1)}
            </Tag>

            {/* Render tags for each substatus */}
            {record.substatus &&
              record.substatus.map((substatus, index) => {
                if (substatus === "opened") {
                  return (
                    <Tooltip key={index} title="Email Opened by Recipient">
                      <Tag className="bg-info-light info">
                        <EyeOutlined
                          style={{ paddingTop: "4", paddingBottom: "4" }}
                        />
                      </Tag>
                    </Tooltip>
                  );
                } else if (substatus === "downloaded") {
                  return (
                    <Tooltip
                      key={index}
                      title="Recipient Downloaded the Check"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Tag className="bg-info-light info">
                        <CloudDownloadOutlined
                          style={{ paddingTop: "4", paddingBottom: "4" }}
                        />
                      </Tag>
                    </Tooltip>
                  );
                } else if (substatus === "canceled") {
                  return (
                    <Tooltip
                      key={index}
                      title="Fulfilment order was canceled"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Tag className="bg-error-light error">
                        <CloseSquareOutlined
                          style={{ paddingTop: "4", paddingBottom: "4" }}
                        />
                      </Tag>
                    </Tooltip>
                  );
                } else if (substatus === "printed") {
                  return (
                    <Tooltip
                      key={index}
                      title="Check has been printed. Will be mailed soon."
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Tag className="bg-info-light info">
                        <PrinterOutlined
                          style={{ paddingTop: "4", paddingBottom: "4" }}
                        />
                      </Tag>
                    </Tooltip>
                  );
                } else if (substatus === "cleared") {
                  return (
                    <Tooltip key={index} title="Check has cleared at the bank">
                      <Tag className="bg-success-light success">
                        <CheckCircleOutlined
                          style={{ paddingTop: "4", paddingBottom: "4" }}
                        />
                      </Tag>
                    </Tooltip>
                  );
                } else if (substatus === "shipped") {
                  return (
                    <Tooltip
                      key={index}
                      title={`Check has been mailed. Click to see tracking for ${record?.fulfillmentMeta?.carrier} ${record?.fulfillmentMeta?.trackingNumber}`}
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Tag
                        className="bg-success-light success"
                        onClick={() => {
                          const trackingNumber =
                            record?.fulfillmentMeta?.trackingNumber;
                          const carrier =
                            record?.fulfillmentMeta?.carrier?.toLowerCase();

                          if (!trackingNumber || !carrier) {
                            return; // Prevent navigation if tracking details are missing
                          }

                          // Define carrier URLs
                          const carrierUrls = {
                            usps: `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`,
                            ups: `https://www.ups.com/track?tracknum=${trackingNumber}`,
                            fedex: `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`,
                          };

                          const url = carrierUrls[carrier];

                          if (url) {
                            window.open(url, "_blank", "noopener,noreferrer");
                          } else {
                            console.warn("Carrier not recognized.");
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <SendOutlined
                          style={{ paddingTop: "4", paddingBottom: "4" }}
                        />
                      </Tag>
                    </Tooltip>
                  );
                } else if (substatus === "pending") {
                  return (
                    <Tooltip
                      key={index}
                      title="Fulfilment order not yet started"
                    >
                      <Tag className="bg-gray-light gray">
                        <HourglassOutlined
                          style={{ paddingTop: "4", paddingBottom: "4" }}
                        />
                      </Tag>
                    </Tooltip>
                  );
                }
              })}
            {record.source === "quickbooks" && (
              <Tooltip title="Imported from QuickBooks Online Integration">
                <Tag className="bg-success-light success">
                  <ApiOutlined style={{ paddingTop: 4, paddingBottom: 4 }} />
                </Tag>
              </Tooltip>
            )}
          </>
        );
      },
    },

    {
      title: "Check Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
    {
      title: "Account Nickname",
      key: "accountNickname",
      render: (text, record) => record?.account?.accountNickname,
    },
    {
      title: "Memo",
      dataIndex: "memo",
      key: "memo",
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={checks}
        pagination={{ pageSize: 50 }}
        style={{ marginTop: 10 }}
      />
      {!loading && isPDFModalVisible && (
        <PrintPreviewModal
          isPDFModalVisible={isPDFModalVisible}
          setPdfModalVisible={setIsPdfModalVisible}
          handlePrintConfirm={handlePrintConfirm}
          selectedChecksToPrint={selectedChecksToPrint}
          template={templates}
          accounts={decAccounts}
          startingCheckNumber={checkNumber}
          formatDate={formatDate}
        />
      )}
    </>
  );
};

export default CheckTable;
