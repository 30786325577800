import React, { useState } from "react";
import { Button, notification, Row, Col, Alert } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiCall from "../../../../../utils/functions/apiCall";

function QbConfigure({ user, currentIntegration, handleSaveConfig }) {
  const [loading, setLoading] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT;
  const [qbClientData, setQbClientData] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const handleGetQbClientData = async () => {
    setLoading(true);
    try {
      const { data } = await apiCall({
        url: `/v1/integrations/quickbooks/getCompanyInfo`,
        method: "POST",
        body: {
          clientId: user.clientId,
          accessToken: user.accessToken,
        },
      });
      if (data) {
        notification.success({ message: "Connection Test Successful" });
      }
      return;
    } catch (error) {
      console.error("Connection test failed", error.message);
      notification.error({
        message: "Connection Test Failed",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePullData = async () => {
    setLoading(true);
    try {
      // Call the API to get the QB client data
      const { data } = await apiCall({
        url: `/v1/integrations/quickbooks/pullData`,
        method: "POST",
        body: {
          clientId: user.clientId,
          accessToken: user.accessToken,
        },
      });
      data(data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // If response is 401 Unauthorized, navigate to logout
        notification.warning({
          message: "Session Expired",
          description: "Your session has expired. Please log in again.",
        });
      } else {
        console.error("Failed to get QB client data:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h3>Entities to Sync with QuickBooks</h3>

      <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
        <li>Bills with unpaid status</li>
        <li>Checks with unprinted status</li>
      </ul>

      <Alert
        description="Vendors, Payees, or Employees associated with the data that does not exist in simple-checks.com will be first looked up via email to see if there's a match. If not, it will insert a new Payee containing the QB ID so it won't duplicate in the future."
        type="info"
        showIcon
        style={{ marginBottom: "20px" }}
      />

      <Row justify="center">
        <Col>
          <Button className="button-green" style={{ marginBottom: 20 }} onClick={handleGetQbClientData} loading={loading}>
            Test Connection
          </Button>
        </Col>
      </Row>

      {qbClientData && (
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col span={12}>
            <pre>{JSON.stringify(qbClientData, null, 2)}</pre>
          </Col>
        </Row>
      )}

      {data && (
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col span={12}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Col>
        </Row>
      )}
    </>
  );
}

export default QbConfigure;
