import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { List, Card, Typography, Button, Space } from "antd";
import PropTypes from "prop-types";

const { Title, Text } = Typography;

const PackageCard = ({ plan, isSubscribed, onSubscribeClick }) => {
  // console.log(plan)
  return (
    <Card
      style={{
        textAlign: 'center',
        height: '100%',
        padding: '20px',
        width: '300px'
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <div>
          <Title level={3} style={{ marginBottom: 8 }}>
            {plan.name}
          </Title>
          <Title level={4} style={{ margin: 0 }}>
            <Text strong>${plan.price / 100}</Text>
            <Text> / {plan.frequency}</Text>
          </Title>
        </div>

        <Button
          type="primary"
          block
          className={
            isSubscribed ? "bg-success-light success" : "bg-info-light info"
          }
          disabled={isSubscribed || plan.disabled}
          onClick={() => onSubscribeClick(plan)}
        >
          {isSubscribed ? "Current" : "Subscribe"}
        </Button>

        <List
          dataSource={plan.features}
          renderItem={(feature) => (
            <List.Item style={{ padding: '8px 0' }}>
              <Space>
                <CheckOutlined style={{ color: '#52c41a' }} />
                <Text>{feature}</Text>
              </Space>
            </List.Item>
          )}
        />
      </Space>
    </Card>
  );
};

export default PackageCard;

PackageCard.propTypes = {
  plan: PropTypes.object.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  onSubscribeClick: PropTypes.func.isRequired,
};
