import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { List } from "antd";
import PropTypes from "prop-types";
import {
  StyledPricingOneBtn,
  StyledPricingOneBtnView,
  StyledPricingOneCard,
  StyledPricingOneCardHeader,
  StyledPricingOneCardHeaderSubtitle,
  StyledPricingOneCardHeaderTitle,
  StyledPricingOneCardList,
  StyledPricingWrapper,
} from "./index.styled";
import { Button } from "antd";

const PackageCard = ({ plan, isSubscribed, onSubscribeClick }) => {
  // console.log(plan)
  return (
    <StyledPricingWrapper style={{ margin: "0" }}>
      <StyledPricingOneCard style={{ margin: "0" }}>
        <StyledPricingOneCardHeader style={{ margin: "0" }}>
          <StyledPricingOneCardHeaderTitle>
            {plan.name}
          </StyledPricingOneCardHeaderTitle>
          <StyledPricingOneCardHeaderSubtitle>
            <span>${plan.price / 100}</span> / {plan.frequency}
          </StyledPricingOneCardHeaderSubtitle>
        </StyledPricingOneCardHeader>
        <StyledPricingOneBtnView>
          <Button
            block
            className={
              isSubscribed ? "bg-success-light success" : "bg-info-light info"
            }
            disabled={isSubscribed || plan.disabled}
            onClick={() => onSubscribeClick(plan)}
          >
            {isSubscribed ? "Current" : "Subscribe"}
          </Button>
        </StyledPricingOneBtnView>
        <StyledPricingOneCardList>
          {plan.features.map((feature, index) => (
            <List.Item key={index}>
              <CheckOutlined className="icon" />
              <p>{feature}</p>
            </List.Item>
          ))}
        </StyledPricingOneCardList>
      </StyledPricingOneCard>
    </StyledPricingWrapper>
  );
};

export default PackageCard;

PackageCard.propTypes = {
  plan: PropTypes.object.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  onSubscribeClick: PropTypes.func.isRequired,
};
