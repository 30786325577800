import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Typography,
  Spin,
  Button,
  Checkbox,
  Input,
  Modal,
  Divider,
  message,
  notification,
  Upload,
  Tag,
} from "antd";
import {
  BankOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  MailOutlined,
  LoginOutlined,
  UploadOutlined,
  FieldBinaryOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../contexts/AuthContext";
import { usePlaidLink } from "react-plaid-link";
import {
  DisplaySignature,
  SignaturePad,
} from "../../../shared/Components/Signature";
import { functions, db, storage } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  collection,
  doc,
  addDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { metaAccountVerificationUpsert } from "../../../services/metadata/accountVerification";
import { sendEmail } from "../../../utils/functions/mailer";
import apiCall from "../../../utils/functions/apiCall";
import { useNavigate } from "react-router-dom";

function BankingVerification({ nextStep, previousStep, onCancel }) {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [accountInfo, setAccountInfo] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const url = process.env.REACT_APP_API_ENDPOINT;
  const [validated, setValidated] = useState(false);
  const [workflow, setWorkflow] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [done, setDone] = useState(false);
  const navigate = useNavigate()

  const VERIFICATION_TYPE = {
    PLAID: "plaid",
    UPLOAD: "upload",
    MICRODEPOSITS: "microdeposits",
  };
  const STATUS = {
    DISABLED: "disabled",
    VERIFIED: "verified",
    PRE_APPROVED: "pre-approved",
    PENDING_VERIFICATION: "pending verification",
  };

  // Fetch Link Token on Component Mount
  useEffect(() => {
    setLoading(true);
    const fetchLinkToken = async () => {
      try {
        // const response = await .post(
        //   `${url}/v1/banking/create-link-token`,
        //   {
        //     userId: "unique-user-id", // Pass userId or other necessary data
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${user.accessToken}`,
        //     },
        //   }
        // );
        const response = await apiCall({
          url: "/v1/banking/create-link-token", // URL to send the POST request
          method: "POST",
          body: {
            userId: "unique-user-id", // Pass userId or other necessary data
          },
        });
        setLinkToken(response?.data?.link_token);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching link token:", error);
      }
    };
    fetchLinkToken(); // Auto-fetch link token when component mounts
  }, [url, user]);

  const updateAccountInfo = (updatedAccount) => {
    // Find the index of the account to be updated
    const updatedAccountIndex = accountInfo.findIndex(
      (account) => account?.account_id === updatedAccount?.account_id
    );

    if (updatedAccountIndex !== -1) {
      // Create a new array with the updated account
      const updatedAccountInfo = [
        ...accountInfo.slice(0, updatedAccountIndex),
        updatedAccount,
        ...accountInfo.slice(updatedAccountIndex + 1),
      ];

      // Update the accountInfo state with the new array
      setAccountInfo(updatedAccountInfo);
    }
  };

  const onSuccess = async (public_token) => {
    try {
      setLoading(true);
      // Send the public token to the backend to complete the process
      // const response = await .post(
      //   `${url}/v1/banking/complete`,
      //   {
      //     public_token: public_token,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${user.accessToken}`,
      //     },
      //   }
      // );
      const response = await apiCall({
        url: "/v1/banking/complete", // URL to send the POST request
        method: "POST",
        body: {
          public_token: public_token,
        },
      });
      // Handle the account information returned from backend
      console.log(response.data);
      setAccountInfo(response?.data.account_info);
      console.log(accountInfo); // Assuming it's an array of accounts
    } catch (error) {
      console.error("Error during Plaid verification:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAccount = async () => {
    // console.log(selectedAccount);
    if (workflow === "upload" && !selectedAccount?.institution_name) {
      notification.warning({
        message: "Please enter the bank name",
      });
      return;
    }

    if (
      workflow === "upload" &&
      // selectedAccount?.routing_number &&
      selectedAccount?.routing_number?.trim().length !== 9
    ) {
      notification.warning({ message: "Routing number must be 9 digits" });
      return;
    }

    if (
      workflow === "upload" &&
      selectedAccount?.routing_number !==
        selectedAccount?.routing_number_validation
    ) {
      notification.warning({ message: "Routing number does not match" });
      return;
    }

    if (
      workflow === "upload" &&
      // selectedAccount?.routing_number &&
      selectedAccount?.account_number?.trim().length < 8
    ) {
      notification.warning({
        message: "US banks account number are at least 8 digits or more",
      });
      return;
    }
    if (
      workflow === "upload" &&
      selectedAccount?.account_number !==
        selectedAccount?.account_number_validation
    ) {
      notification.warning({ message: "Account number does not match" });
      return;
    }

    if (!selectedAccount?.accountNickname) {
      notification.warning({
        message: "Give the account a nickname to show in the dropdowns",
      });
      return;
    }

    if (workflow === "upload" && !selectedAccount?.accountNickname) {
      notification.warning({
        message: "Please enter a nickname for the account",
      });
      return;
    }
    if (!selectedAccount?.ownerName) {
      notification.warning({
        message:
          "Please enter the name of the owner of the account as it will show on top left of the check",
      });
      return;
    }
    if (!selectedAccount?.addressLine1) {
      notification.warning({
        message:
          "Please enter the address for the owner of the account as it will show on top left of the check",
      });
      return;
    }
    if (!selectedAccount?.addressLine2) {
      notification.warning({
        message:
          "Please enter the city, state and zip for the owner of the account as it will show on top left of the check",
      });
      return;
    }
    if (workflow === "upload" && !selectedAccount?.verificationFile) {
      notification.warning({
        message:
          "Please upload a picture of a void check OR a bank document showing: Account owner name, routing, account number ",
      });
      return;
    }
    setLoading(true);
    const verificationFile = selectedAccount?.verificationFile || "";
    delete selectedAccount?.verificationFile;
    if (workflow !== VERIFICATION_TYPE.PLAID) {
      selectedAccount.mask = selectedAccount?.account_number.slice(0, -4);
    }
    // Ensure selectedAccount.signature is explicitly true or false
    const updatedAccount = {
      ...selectedAccount,
      signature: !!selectedAccount?.signature, // Ensure it's a boolean
    };

    try {
      // Step 1: Call the cloud function to encrypt the data
      const encryptFunction = httpsCallable(functions, "encryptData");
      const {
        data: { encryptedData },
      } = await encryptFunction({
        plainText: JSON.stringify(updatedAccount),
      });

      // Step 2: Save the encrypted data to Firestore
      const userDocRef = doc(db, "clients", user.clientId);
      const accountsCollectionRef = collection(userDocRef, "accounts");
      let status = STATUS.VERIFIED;
      if (workflow === VERIFICATION_TYPE.UPLOAD) {
        status = STATUS.PRE_APPROVED;
      } else if (workflow === VERIFICATION_TYPE.MICRODEPOSITS) {
        status = STATUS.PENDING_VERIFICATION;
      }

      const accId = await addDoc(accountsCollectionRef, {
        encryptedAccount: encryptedData, // Save the encrypted data
        createdAt: serverTimestamp(), // Use Firestore server timestamp
        isDeleted: false,
        isDisabled: false,
        verificationFile: verificationFile,
        verificationType: workflow,
        ownerName: selectedAccount.ownerName,
        status,
        accountNickname: updatedAccount?.accountNickname,
      });

      setSelectedAccount({ ...selectedAccount, id: accId.id });
      message.success({ content: "Account added successfully" });

      // Optionally update the local state
      updateAccountInfo({ ...updatedAccount, added: true });
      //
      const clientRef = doc(db, "clients", user.clientId); // Reference to the client document
      await setDoc(
        clientRef,
        {
          verifications: {
            bank: true,
          },
        },
        { merge: true } // Use merge to update only verifications.sms
      );
      //update the user without refreshing
      setUser({
        ...user,
        clientData: {
          ...user.clientData,
          verifications: {
            ...user.clientData.verifications,
            bank: true,
          },
        },
      });

      //specific logic

      if (workflow === VERIFICATION_TYPE.UPLOAD) {
        try {
          metaAccountVerificationUpsert(user, {
            ...selectedAccount,
            verificationFile,
          });
          await sendEmail(
            user,
            process.env.REACT_APP_ADMIN_EMAIL,
            "New Verification Request",
            `client ${
              user.clientId
            } submitted a new account verification request via upload. ${JSON.stringify(
              selectedAccount
            )}`
          );
        } catch (e) {
          console.log(e.message);
        }
      }

      //continue setting flags
      setSelectedAccount(null);
      setSignatureModalOpen(false);
      setValidated(true);
      setDone(true);
      setFileList([]);
      setWorkflow("");
      navigate('/settings/bank-accounts)')
      onCancel();
    } catch (error) {
      message.error({ content: "Failed to add account. Please try again." });
      console.error("Error in adding account:", error);
    } finally {
      setLoading(false);
    }
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit: () => {
      setAccountInfo([]);
      setWorkflow("");
      setSelectedAccount(null);
    },
  });

  const handleFileUpload = async (file) => {
    setLoading(true);
    const fileName = `accountValidation_${uuidv4()}_${file.name}`;
    const storageRef = ref(
      storage,
      `verification/${user.clientId}/${fileName}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFileUploadProgress(progress);
      },
      (error) => {
        message.error({ content: "Upload failed. Please try again." });
        setLoading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        message.success({ content: "File uploaded successfully." });
        setFileList([
          { name: file.name, url: downloadURL, cloudFileName: fileName },
        ]);

        setSelectedAccount({
          ...selectedAccount,
          verificationFile: fileName,
        });
        setUploadSuccess(true);
        setLoading(false);
      }
    );
  };

  const handleFileRemove = async (file) => {
    setLoading(true);
    try {
      // Ensure you're using the correct non-root reference by using the saved firebasePath
      if (!file.cloudFileName) {
        throw new Error("Invalid file path for deletion.");
      }
      // Recreate the reference to the file using its full path stored in firebasePath
      const fileRef = ref(
        storage,
        `verification/${user.clientId}/${file.cloudFileName}`
      );

      // Delete the file from Firebase Storage
      await deleteObject(fileRef);

      // Update the file list state by removing the file
      setFileList([]);
      setSelectedAccount({ ...selectedAccount, verificationFile: null });
      message.success({ content: `File removed successfully.` });
    } catch (error) {
      message.error({ content: `Failed to remove file` });
    } finally {
      setLoading(false);
    }
  };

  // const handleEncrypt = async () => {
  //   try {
  //     const encryptFunction = httpsCallable(functions, "encryptData");
  //     const encrypted = await encryptFunction({
  //       plainText: JSON.stringify(selectedAccount),
  //     });

  //     setTest(encrypted.data.encryptedData);
  //   } catch (error) {
  //     console.error("Error encrypting data:", error);
  //   }
  // };

  // const handleDecrypt = async () => {
  //   console.log(test);
  //   const decryptFunction = httpsCallable(functions, "decryptData");
  //   const decrypted = await decryptFunction({
  //     encryptedText: test,
  //   });

  //   const rehydrated = JSON.parse(decrypted.data.plainText);
  //   setTest(rehydrated);
  // };

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        {(workflow === "" || (workflow === "plaid" && !selectedAccount)) && (
          <Col xs={24} sm={24}>
            {/* <Card style={{ height: "500px", overflow: "auto" }}> */}
            <Row
              className="bg-success-light"
              style={{
                borderRadius: 10,
                padding: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BankOutlined className="success" style={{ fontSize: "3rem" }} />
            </Row>
            <Row>
              {/* {accountInfo.length === 0 && workflow !== "plaid" && (
                <Col xs={24} style={{ textAlign: "center", marginTop: -10 }}>
                  <BankOutlined className="info" style={{ fontSize: "2rem" }} />
                  <div
                    style={{
                      width: "100%",
                      paddingBottom: 20,
                      paddingTop: 10,
                      marginTop: 15,
                      border: "1px solid lightgray",
                      borderRadius: "10px",
                    }}
                    className="background-lightGray"
                  >
                    <Typography.Title level={5} style={{ marginTop: 10 }}>
                      Option 1 - Manual Entry
                    </Typography.Title>
                    <Tag color="success">Instant Validation</Tag>
                    <Typography.Paragraph style={{ marginTop: 10 }}>
                      Upload a picture of a <strong>void check</strong> OR bank
                      document with account name, routing, and account number.
                    </Typography.Paragraph>
                    <Button
                      type="primary"
                      onClick={() => {
                        setAccountInfo([]);
                        setWorkflow(`upload`);
                      }}
                      // disabled={!ready || loading}
                    >
                      <UploadOutlined /> Manual Entry
                    </Button>
                  </div>
                </Col>
              )} */}
              <Col xs={24} style={{ textAlign: "center", marginTop: -10 }}>
                <Typography.Title level={5}>
                  Authenticate with Bank
                </Typography.Title>
                <Tag color="success">Instant Validation</Tag>
                {/* <Tag color="blue">Easier</Tag> */}
                <Typography.Paragraph style={{ marginTop: 10 }}>
                  Authenticate with the bank you intend to print checks for.
                </Typography.Paragraph>
                <div>
                  {loading ? (
                    <Spin />
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        setWorkflow(`plaid`);
                        open();
                      }}
                      disabled={!ready || loading}
                    >
                      <LoginOutlined /> Authenticate With Bank
                    </Button>
                  )}
                  {accountInfo.length > 0 && (
                    <div>
                      <Typography.Title
                        className="text-gray"
                        level={5}
                        style={{ marginBottom: 20 }}
                      >
                        Linked Accounts:
                      </Typography.Title>
                      {accountInfo.map((account, index) => (
                        <React.Fragment key={index}>
                          <Row gutter={16}>
                            <Col xs={18} style={{ textAlign: "left" }}>
                              <Typography.Text>
                                {(account.nickname || account.name) +
                                  " - " +
                                  account.mask}
                              </Typography.Text>
                            </Col>
                            <Col xs={6}>
                              {account.added ? (
                                <CheckCircleOutlined className="success" />
                              ) : (
                                <Button
                                  className="button-yellow"
                                  style={{ marginLeft: "-20px" }}
                                  onClick={() => setSelectedAccount(account)}
                                >
                                  <ExclamationCircleOutlined /> Review
                                </Button>
                              )}
                            </Col>
                          </Row>
                          <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                        </React.Fragment>
                      ))}
                      <Button
                        className="button-gray"
                        style={{ marginTop: 25 }}
                        onClick={() => {
                          setSelectedAccount(null);
                          setWorkflow("");
                          setAccountInfo([]);
                        }}
                        disabled={loading}
                      >
                        <LeftOutlined />
                        Back
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {/* </Card> */}
          </Col>
        )}
        {/* Card for showing selected account details */}
        <Col xs={24} sm={24}>
          {selectedAccount && workflow === `plaid` && (
            <Card
              style={{ height: "600px" }}
              title="Account Details"
              type="inner"
            >
              <Button
                className="button-gray"
                style={{ marginBottom: 10 }}
                onClick={() => {
                  setSelectedAccount(null);
                  // setWorkflow("");
                }}
                loading={loading}
              >
                <LeftOutlined />
                Back
              </Button>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Typography.Paragraph>
                    Institution Name: {selectedAccount?.institution_name}
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Account Name: {selectedAccount?.name}
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Routing Number: {selectedAccount?.routing_number}
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    Account Number: {selectedAccount?.account_number}
                  </Typography.Paragraph>
                </Col>
                <Col xs={24}>
                  <Input
                    value={selectedAccount?.accountNickname}
                    placeholder="Account Nickname: BAC Checking - 1234"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        accountNickname: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Divider style={{ marginTop: 0, marginBottom: 10 }} />
                <Col xs={24}>
                  <Typography.Text>
                    <strong>
                      {" "}
                      Your info that prints on the top left of check
                    </strong>
                  </Typography.Text>
                  <Input
                    value={selectedAccount?.ownerName}
                    placeholder="Business or Individual Name"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        ownerName: e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={selectedAccount?.addressLine1}
                    placeholder="123 Main St #200 (Address Line 1)"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        addressLine1: e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={selectedAccount?.addressLine2}
                    placeholder="Miami, Florida, 33076 (Address Line 2)"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        addressLine2: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Modal
                  open={signatureModalOpen}
                  onClose={() => setSignatureModalOpen(false)}
                  footer={null}
                  maskClosable={false}
                  closeIcon={null}
                >
                  <SignaturePad
                    isOpen={signatureModalOpen}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    onClose={() => setSignatureModalOpen(false)}
                    renderPad={signatureModalOpen}
                  />
                </Modal>
                <Col xs={24}>
                  <Button
                    block
                    style={{ marginTop: 10 }}
                    className="button-green"
                    onClick={handleAddAccount}
                    disabled={
                      loading
                      // (selectedAccount.signature &&
                      //   !selectedAccount.signatureImg) ||
                      // !selectedAccount.accountNickname ||
                      // !selectedAccount.ownerName ||
                      // !selectedAccount.addressLine1 ||
                      // !selectedAccount.addressLine2
                    }
                    loading={loading}
                  >
                    Add Account
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
          {workflow === `upload` && (
            <Card
              style={{
                height: "820px",
                overflowY: "auto",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Button
                    className="button-gray"
                    style={{ marginBottom: 10 }}
                    onClick={() => {
                      setSelectedAccount(null);
                      setWorkflow("");
                    }}
                    loading={loading}
                  >
                    <LeftOutlined />
                    Back
                  </Button>
                  <Input
                    value={selectedAccount?.institution_name || ""}
                    placeholder="Bank / Institution Name - ex: Truist N/A"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        institution_name: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <Input
                    value={selectedAccount?.routing_number || ""}
                    placeholder="Routing Number"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        routing_number: e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={selectedAccount?.routing_number_validation || ""}
                    placeholder="Validate Routing Number"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        routing_number_validation: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <Input
                    value={selectedAccount?.account_number || ""}
                    placeholder="Account Number"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        account_number: e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={selectedAccount?.account_number_validation || ""}
                    placeholder="Validate Account Number"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        account_number_validation: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <Input
                    value={selectedAccount?.accountNickname || ""}
                    placeholder="Account Nickname: BAC Checking - 1234"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        accountNickname: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Typography.Text>
                  <strong>Info that prints on the top left of check</strong>
                </Typography.Text>
                <Col xs={24}>
                  <Input
                    value={selectedAccount?.ownerName}
                    placeholder="Business or Account Owner Name"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        ownerName: e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={selectedAccount?.addressLine1}
                    placeholder="123 Main St #200 (Address Line 1)"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        addressLine1: e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={selectedAccount?.addressLine2}
                    placeholder="Miami, Florida, 33076 (Address Line 2)"
                    required={true}
                    onChange={(e) => {
                      setSelectedAccount({
                        ...selectedAccount,
                        addressLine2: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                <Typography.Text style={{ marginBottom: 10 }}>
                  <strong> Upload</strong>
                </Typography.Text>
                <Typography.Text style={{ marginBottom: 10 }}>
                  Upload a picture of a <strong>void check</strong> OR a bank
                  document showing: Account owner name, routing, account number
                </Typography.Text>
                <Col xs={24}>
                  <Upload
                    beforeUpload={(file) => {
                      handleFileUpload(file);
                      return false;
                    }}
                    fileList={fileList}
                    maxCount={1}
                    onRemove={(file) => {
                      handleFileRemove(file);
                      return false;
                    }}
                    disabled={loading}
                  >
                    <Button icon={<UploadOutlined />} loading={loading}>
                      Upload Document
                    </Button>
                  </Upload>
                </Col>
                {fileUploadProgress > 0 && fileUploadProgress < 100 && (
                  <Typography.Text>
                    Upload Progress: {Math.round(fileUploadProgress)}%
                  </Typography.Text>
                )}
                <Modal
                  open={signatureModalOpen}
                  onClose={() => setSignatureModalOpen(false)}
                  footer={null}
                  maskClosable={false}
                  closeIcon={null}
                >
                  <SignaturePad
                    isOpen={signatureModalOpen}
                    selectedAccount={selectedAccount}
                    setSelectedAccount={setSelectedAccount}
                    onClose={() => setSignatureModalOpen(false)}
                    renderPad={signatureModalOpen}
                  />
                </Modal>
                <Col xs={24}>
                  <Button
                    block
                    style={{ marginTop: 10 }}
                    className="button-green"
                    onClick={handleAddAccount}
                    loading={loading}
                  >
                    Add Account
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={18} style={{ marginTop: 30 }}>
          <Typography.Paragraph className="success">
            <LockOutlined /> This information is secure and encrypted
          </Typography.Paragraph>
        </Col>
        {/* <Col xs={3} style={{ textAlign: "right", marginTop: 30 }}>
          {workflow && accountInfo && (
            <Button
              type="default"
              onClick={() => {
                setAccountInfo([]);
                setWorkflow(null);
                setSelectedAccount(null);
                setDone(false);
              }}
            >
              Start Over
            </Button>
          )}
        </Col>
        <Col xs={3} style={{ textAlign: "right", marginTop: 30 }}>
          {done && (
            <Button
              type="primary"
              onClick={() => {
                setAccountInfo([]);
                setWorkflow(null);
                setSelectedAccount(null);
                setDone(false);
                onCancel();
              }}
            >
              Close
            </Button>
          )}
        </Col> */}
      </Row>
    </>
  );
}

export default BankingVerification;
