import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Modal,
  Form,
  Select,
  Input,
  notification,
} from "antd";
import { PrinterOutlined, MailOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { formatDate } from "../../../utils/functions/dates";
import { currencyFormatter } from "../../../utils/functions/numbers";
import axios from "axios";
import { set } from "lodash";

const { Option } = Select;

function Checks({
  checks,
  user,
  fulfillmentId,
  updateCheckToPrinted,
  updateCheckToShipped,
  updateCheckToCanceled,
}) {
  const [shippingModalOpen, setShippingModalOpen] = useState(false);
  const [currentCheck, setCurrentCheck] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const formatFirestoreDate = (timestamp) => {
    if (!timestamp) return "No Date Available";
    if (typeof timestamp === "object" && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
      return date.toLocaleDateString(); // Format the date
    }
    return "Invalid Date"; // Handle unexpected cases
  };

  const handleAddShippingInfo = (record) => {
    setCurrentCheck(record);
    setCarrier(null);
    setTrackingNumber("");
    setShippingModalOpen(true);
  };

  const handleSaveShippingInfo = async () => {
    const record = currentCheck;
    if (!carrier || !trackingNumber) {
      notification.error({ message: "Please fill out all fields." });
      return;
    }
    setLoading(true);
    try {
      updateCheckToShipped(record.id, carrier, trackingNumber);
    } catch (error) {
      notification.error({ message: "An error occurred. Please try again." });
    } finally {
      setLoading(false);
      setShippingModalOpen(false);
    }
  };

  const columns = [
    ...(user?.role?.toLowerCase() === "employee"
      ? [
          {
            title: "Action",
            key: "action",
            render: (_, record) => {
              // Check if substatus includes "canceled"
              if (record?.substatus?.includes("canceled")) {
                return null; // Do not render anything for this record
              }

              return (
                <>
                  {/* <Tooltip title="Print">
                    <Button
                      icon={<PrinterOutlined className="info" />}
                      type="dashed"
                      shape="circle"
                      className="quick-print-btn"
                      onClick={() => updateCheckToPrinted(record.id)}
                    ></Button>
                  </Tooltip> */}

                  <Tooltip title="Add Shipping Info">
                    <Button
                      icon={<MailOutlined className="warning" />}
                      type="dashed"
                      shape="circle"
                      style={{ marginLeft: 10 }}
                      onClick={() => handleAddShippingInfo(record)}
                    ></Button>
                  </Tooltip>
                </>
              );
            },
          },
        ]
      : []),
    {
      title: "Check Number",
      dataIndex: "checkNumber",
      key: "checkNumber",
    },
    {
      title: "Status",
      dataIndex: "substatus",
      key: "substatus",
      render: (substatus) => substatus?.join(", "),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${currencyFormatter(amount)}`,
    },
    {
      title: "Payable To",
      dataIndex: "payableTo",
      key: "payableTo",
    },
    {
      title: "Date",
      dataIndex: "date", // Maps the Firestore Timestamp
      key: "date",
      render: (date) => formatFirestoreDate(date), // Formats the Firestore Timestamp
    },
    {
      title: "Shipping Date",
      key: "shippingDate",
      render: (record) => record?.fulfillmentMeta?.shippingDate ? formatDate(record?.fulfillmentMeta?.shippingDate) : "N/A",
    },
    {
      title: "Carrier",
      key: "carrier",
      render: (record) => record?.fulfillmentMeta?.carrier || "N/A",
    },
    {
      title: "Tracking Number",
      key: "trackingNumber",
      render: (record) => {
        const trackingNumber = record?.fulfillmentMeta?.trackingNumber;
        const carrier = record?.fulfillmentMeta?.carrier?.toLowerCase();
    
        if (!trackingNumber || !carrier) {
          return "N/A";
        }
    
        // Define carrier URLs
        const carrierUrls = {
          usps: `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`,
          ups: `https://www.ups.com/track?tracknum=${trackingNumber}`,
          fedex: `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`,
        };
    
        // Get the URL for the carrier
        const url = carrierUrls[carrier];
    
        if (!url) {
          return trackingNumber; // If carrier isn't recognized, return plain tracking number
        }
    
        // Return clickable link
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {trackingNumber}
          </a>
        );
      },
    },
    
  ].filter(Boolean);

  return (
    <>
      <Row style={{ marginTop: 15 }}>
        <Col xs={24}>
          <Table
            rowKey="id" // Ensure each check has a unique id
            columns={columns}
            dataSource={checks}
          />
        </Col>
      </Row>

      <Modal
        title="Add Shipping Information"
        open={shippingModalOpen}
        onCancel={() => setShippingModalOpen(false)}
        footer={null}
      >
        <Form layout="vertical">
          <Form.Item label="Carrier" required>
            <Select value={carrier} onChange={(value) => setCarrier(value)}>
              <Option value="USPS">USPS</Option>
              <Option value="FedEx">FedEx</Option>
              <Option value="UPS">UPS</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Tracking Number" required>
            <Input
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              placeholder="Enter tracking number"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              loading={loading}
              onClick={handleSaveShippingInfo}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Checks;
