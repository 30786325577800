import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Table,
  Checkbox,
  notification,
  Form,
  Row,
  Col,
  Select,
  Button,
  Alert,
  Image,
} from "antd";
import { formatDate } from "../../../utils/functions/dates";
import { qbTemplate } from "../../../shared/checkTemplates/qbTemplate";
import { checkStock } from "../../../shared/checkTemplates/checkStockTemplate";
import { blankPaper } from "../../../shared/checkTemplates/blankPaperTemplate";
import { blankPaperEndorsementBack } from "../../../shared/checkTemplates/blankPaperTemplateEndorsementBack";
import { CloseCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import PrintPreviewModal from "./PrintPreview";
import { decryptAccounts } from "../../../utils/functions/encryption";

const PrintChecksModal = ({
  isVisible,
  onOk,
  onCancel,
  accounts,
  selectedChecksToPrint,
  setSelectedChecksToPrint,
  startingCheckNumber,
  setStartingCheckNumber,
  template,
  isPDFModalVisible,
  handlePrintConfirm,
  setIsPrintModalVisible,
  setIsPDFModalVisible,
  resetFilteredChecks,
}) => {
  const [templates, setTemplates] = useState([]);
  const [form] = Form.useForm();
  const [decAccounts, setDecAccounts] = useState([]);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null); // Track selected template
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    let templateValues = [];
    if (template && template.length > 0) {
      templateValues.push(...template);
    }
    templateValues.push(
      blankPaper,
      blankPaperEndorsementBack,
      checkStock,
      qbTemplate
    );
    setTemplates(templateValues);

    const handleDecryption = async () => {
      if (accounts && accounts.length > 0) {
        const results = await decryptAccounts(accounts);
        setDecAccounts(results);
      }
    };

    handleDecryption();
  }, [accounts]);

  useEffect(() => {
    form.setFieldValue("startingCheckNumber", startingCheckNumber);
  }, [startingCheckNumber]);

  // Define columns for desktop and mobile
  const columns = [
    {
      title: "Select",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedChecksToPrint.includes(record)}
          onChange={() => {
            let selected = [...selectedChecksToPrint];
            if (selected.includes(record)) {
              setSelectedChecksToPrint(
                selected.filter((item) => item.id !== record.id)
              );
            } else {
              selected.push(record);
              selected = selected.filter((item) => !item.isDeleted);
              setSelectedChecksToPrint(selected);
            }
          }}
        />
      ),
    },
    {
      title: "Check Number",
      dataIndex: "checkNumber",
      key: "checkNumber",
      responsive: ["md"],
    },
    {
      title: "Account",
      key: "accountNickname",
      render: (text, record) => record.account?.accountNickname || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
      responsive: ["md"],
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    {
      title: "Check Memo",
      dataIndex: "memo",
      key: "memo",
      responsive: ["md"],
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  const getAlertMessage = () => {
    if (selectedTemplateIndex === null) return null;

    const selectedTemplate = templates[selectedTemplateIndex];
    if (selectedTemplate === checkStock) {
      return (
        <Alert
          showIcon={true}
          message={
            <>
              Check Stock Paper is the best way to print checks. You can buy
              blank check stock paper{" "}
              <a
                href="https://simple-checks.com/product/blank-laser-ink-jet-prismatic-red-blue-laser-check-stock-check-on-the-top-250-ct/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong> here </strong>
              </a>
              .
            </>
          }
          type="info"
        />
      );
    }

    if (selectedTemplate === blankPaper) {
      return (
        <Alert
          showIcon={true}
          message={
            <>
              Print on any white paper
              in a single page with endorsement at the bottom. Optimized for
              mobile deposit. For video with print & deposit instructions{" "}
              <a
                href="https://simple-checks.com/product/blank-laser-ink-jet-prismatic-red-blue-laser-check-stock-check-on-the-top-250-ct/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>click here</strong>
              </a>
              . Print quality can affect bank acceptance. 
            </>
          }
          type="info"
        />
      );
    }
    if (selectedTemplate === blankPaperEndorsementBack) {
      return (
        <Alert
          showIcon={true}
          message={
            <>
              Generate 2 pages with
              front and back of the check. Print using double sided feature from
              your printer OR flip the page manually. For video with
              print & deposit instructions {" "}
              <a
                href="https://simple-checks.com/product/blank-laser-ink-jet-prismatic-red-blue-laser-check-stock-check-on-the-top-250-ct/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>click here</strong>
              </a>
              . Print quality can affect bank acceptance. 
            </>
          }
          type="info"
        />
      );
    }
    if (selectedTemplate === qbTemplate) {
      return (
        <Alert
          showIcon={true}
          message={
            <>
              Print on a QuickBooks compatible check template. QB check paper
              already have the business name, check number, account & routing
              numbers pre-printed. See imgage example.
            </>
          }
          type="info"
        />
      );
    }

    return null;
  };

  return (
    <>
      <Modal
        title="Select Checks to Print"
        open={isVisible}
        okText="Generate Checks"
        onOk={() => {
          if (!startingCheckNumber) {
            notification.error({ message: "Enter starting check number" });
            return;
          }

          const selectedTemplate = form.getFieldValue("template");
          if (selectedTemplate === undefined || selectedTemplate === null) {
            notification.warning({ message: "Please select a check template" });
            return;
          }

          onOk();
        }}
        onCancel={onCancel}
        style={{
          minWidth: "90%",
          marginTop: isMobile ? 10 : -70,
          minHeight: "80vh",
          minHeight: "100vh",

          overflowY: "auto",
          marginTop: "-70",
        }}
        maskClosable={false}
      >
        <Row>
          <Col
            md={19}
            style={{
              backgroundColor: "#ecf0f1",
              padding: 10,
              borderRadius: "10px 0px 0px 10px",
              marginBottom: 15,
            }}
          >
            <Form layout="vertical" form={form}>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={5}>
                  <Form.Item
                    label="Starting Check #"
                    value={startingCheckNumber}
                    onChange={(e) => setStartingCheckNumber(e.target.value)}
                    name="startingCheckNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the starting check number",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: 120 }}
                      value={startingCheckNumber}
                      onChange={(e) => setStartingCheckNumber(e.target.value)}
                      placeholder="Starting Check #"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={14}>
                  <Form.Item
                    name="template"
                    label="Check Print Template"
                    rules={[
                      {
                        required: true,
                        message: "Please select check template",
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => setSelectedTemplateIndex(value)} // Update selected template
                    >
                      {templates?.map((template, index) => (
                        <Select.Option key={template.title} value={index}>
                          {template.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5}>
                  <Button
                    type="primary"
                    style={{ width: "100%", marginTop: 30 }}
                    onClick={() =>
                      window.open(
                        "https://simple-checks.com/choosing-the-right-check-template/",
                        "_blank"
                      )
                    }
                  >
                    <QuestionCircleOutlined /> Help Me Choose
                  </Button>
                </Col>
              </Row>
              {selectedTemplateIndex !== null && (
                <Row>
                  <Col xs={24}>{getAlertMessage()}</Col>
                </Row>
              )}
            </Form>
          </Col>
          <Col
            md={5}
            style={{
              backgroundColor: "#ecf0f1",
              padding: 10,
              marginLeft: 0,
              borderRadius: "0px 10px 10px 0px",
              marginBottom: 15,
              display: "flex", // Enable flexbox
              justifyContent: "center", // Horizontally align content
              alignItems: "center",
            }}
          >
            {templates[selectedTemplateIndex]?.templateId === "qb" && (
              <>
                <Image
                  height={130}
                  style={{maxWidth:200}}
                  className="shadow-square"
                  src="/images/checkTemplates/qbPrintFinal.jpg"
                />
              </>
            )}
            {templates[selectedTemplateIndex]?.templateId === "checkStock" && (
              <>
                <Image
                  height={130}
                  style={{maxWidth:200}}

                  className="shadow-square"
                  src="/images/checkTemplates/stockPaperFinalPrint.jpg"
                />
              </>
            )}
            {templates[selectedTemplateIndex]?.templateId === "blank" && (
              <>
                <Image
                  height={130}
                  style={{maxWidth:100}}

                  className="shadow-square"
                  src="/images/checkTemplates/blankSinglePageFinal.jpg"
                />
              </>
            )}
            {templates[selectedTemplateIndex]?.templateId ===
              "blankTwoPages" && (
              <>
                <Image
                  height={130}
                  style={{maxWidth:130}}

                  className="shadow-square"
                  src="/images/checkTemplates/blankTwoPageFinal.jpg"
                />
              </>
            )}
          </Col>
        </Row>
        <Button
          onClick={resetFilteredChecks}
          className="button-gray"
          style={{ marginBottom: 10 }}
        >
          <CloseCircleOutlined />
          Reset Filter
        </Button>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={selectedChecksToPrint}
          pagination={false}
          scroll={{ x: true }}
        />
      </Modal>
      {isPDFModalVisible === true && (
        <PrintPreviewModal
          isPDFModalVisible={isPDFModalVisible}
          handlePrintConfirm={handlePrintConfirm}
          setIsPDFModalVisible={setIsPDFModalVisible}
          setIsPrintModalVisible={setIsPrintModalVisible}
          selectedChecksToPrint={selectedChecksToPrint}
          templates={templates[form.getFieldValue("template")]}
          accounts={decAccounts}
          startingCheckNumber={startingCheckNumber}
          formatDate={formatDate}
        />
      )}
    </>
  );
};

export default PrintChecksModal;
