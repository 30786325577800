import axios from "axios";
import { getAuth } from "firebase/auth";

// Utility to get fresh user from Firebase
const getFreshUser = () => {
  const auth = getAuth();
  return auth.currentUser; // Retrieve the current Firebase User
};

// Updated ApiCall function
const apiCall = async ({
  url,
  method = "GET",
  headers = {},
  body = null,
  params = null,
  authenticated = true,
  maxRetries = 3,
  bodyAccessToken = false,
}) => {
  let retries = 0;

  // Fetch the fresh user if necessary
  let authToken = null;
  let user = null;
  if (authenticated) {
    user = getFreshUser(); // Retrieve the current Firebase user object
    if (!user) throw new Error("No authenticated user found.");

    authToken = await user.getIdToken(true);

    if (bodyAccessToken) {
      // Append the token to the request body if required
      body = { ...body, accessToken: authToken };
    } else {
      // Add the token to the headers
      headers = {
        ...headers,
        Authorization: `Bearer ${authToken}`,
      };
    }
  }

  while (retries <= maxRetries) {
    try {
      // Configure Axios request
      const response = await axios({
        url: `${process.env.REACT_APP_API_ENDPOINT}${url}`,
        method,
        headers,
        data: body,
        params,
      });

      return response; // Return successful response
    } catch (error) {
      retries += 1;

      const isTransientError = error.response?.status >= 500 || !error.response;
      if (retries > maxRetries || !isTransientError) {
        console.error(
          `Failed after ${retries} attempt(s):`,
          error.response || error.message
        );
        throw error;
      }

      console.warn(
        `Retrying API call (attempt ${retries} of ${maxRetries})...`
      );
    }
  }
};

export default apiCall;
