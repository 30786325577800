import apiCall from "./apiCall";

export function sendEmail(user, email, subject, message, htmlMessage) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  console.log(apiUrl);
  try {
    // return .post(
    //   `${apiUrl}/v1/mailer`,
    //   {
    //     email: email,
    //     subject: subject,
    //     message: message,
    //     htmlMessage: htmlMessage,
    //   },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + user.accessToken,
    //     },
    //   }
    // );
    return apiCall({
      url: "/v1/mailer",
      method: "POST",
      body: {
        email: email,
        subject: subject,
        message: message,
        htmlMessage: htmlMessage,
      },
    });
  } catch (e) {
    console.error(e.message);
    return e.message;
  }
}
