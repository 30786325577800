import React, { useState, useEffect } from "react";
import { Table, Button, Card, Row, Col } from "antd";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { RocketOutlined, SendOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "../../utils/functions/numbers";
import { formatDate } from "../../utils/functions/dates";

const FulfillOrders = () => {
  const [orders, setOrders] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchFulfillmentOrders = async () => {
    try {
      const ordersCollection = query(
        collection(db, "fulfillmentOrders"),
        where("clientId", "==", user.clientId),
        orderBy("createdAt", "desc")
      );
      const ordersSnapshot = await getDocs(ordersCollection);
      const ordersList = ordersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        checksCount: doc.data().checks?.length || 0,
        documentsCount: doc.data().documents?.length || 0,
      }));
      setOrders(ordersList);
    } catch (error) {
      console.error("Error fetching fulfillment orders:", error);
    }
  };

  useEffect(() => {
    fetchFulfillmentOrders();
  }, []);

  const columns = [
    {
      title: "Action",
      key: "action",
      render: (order) => (
        <Button
          type="primary"
          onClick={() => {
            navigate(`/fulfillment/${order.id}`);
          }}
        >
          Open
        </Button>
      ),
    },
    {
      title: "Date",
      key: "date",
      render: (text, record) => formatDate(record.createdAt),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      filters: Array.from(new Set(orders.map((order) => order.clientName))).map(
        (name) => ({
          text: name,
          value: name,
        })
      ),
      onFilter: (value, record) => record.clientName === value,
    },
    {
      title: "Number of Checks",
      dataIndex: "checksCount",
      key: "checksCount",
    },
    {
      title: "Number of Documents",
      dataIndex: "documentsCount",
      key: "documentsCount",
    },
    {
      title: "Shipment Method",
      dataIndex: "shippingService",
      key: "shippingService",
    },
    {
      title: "Pricing",
      key: "pricing",
      render: (text, record) =>
        `$${currencyFormatter(record.pricingInfo.totalPrice)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Shipped", value: "shipped" },
        { text: "Printed", value: "printed" },
        { text: "Canceled", value: "canceled" },
      ],
      onFilter: (value, record) => record.status === value,
    },
  ];

  return (
    <>
      <h1 className="monospace">
        <RocketOutlined /> Fulfill Orders
      </h1>
      <Card style={{ width: "100%" }}>
        <Row></Row>

        <Row style={{ marginTop: 15 }}>
          <Col xs={24}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={orders}
              pagination={{ pageSize: 10 }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default FulfillOrders;
