import React, { useEffect, useState } from "react";
import PackageOne from "./PackageOne";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Card, Modal, Row, Col } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../../contexts/AuthContext";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import CancelSubscriptionButton from "./CancelSubscription";
import UpdatePaymentInformation from "./UpdatePayment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const Pricing = () => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [subId, setSubId] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    //get remote config
    const fetchRemoteConfig = async () => {
      try {
        // Fetch and activate the Remote Config values
        // await fetchAndActivate(remoteConfig);

        // // Get the value of the "plans" variable
        // const plansValue = getValue(remoteConfig, "plans").asString();
        // console.log(plansValue);

        // // Parse the JSON string into an array of objects
        // const parsedPlans = JSON.parse(plansValue);

        // Update the state with the parsed plans
        // setPlans(parsedPlans);
        const plans = [
          {
            name: "Starter",
            stripeProductId: process.env.REACT_APP_STRIPE_PRODUCT_ID_STARTER,
            price: 899,
            frequency: "month",
            disabled: false,
            features: [
              "Unlimited Checks",
              "Unlimited Payees",
              "One user",
              "One bank account",
              "Print on any paper",
              "eChecks - send via email",
              "Check fulfillment and mailing for a fee",
            ],
          },
          {
            name: "Premium",
            stripeProductId: process.env.REACT_APP_STRIPE_PRODUCT_ID_PREMIUM,
            price: 1499,
            frequency: "month",
            disabled: false,
            features: [
              "All Starter features plus:",
              "Up to 5 users",
              "Up to 5 bank accounts",
              "Import checks",
            ],
          },
        ];
        setPlans(plans);
      } catch (error) {
        console.error("Error fetching remote config:", error);
        setPlans([]);
      }
    };

    fetchRemoteConfig();
    if (user?.clientData?.subscription?.stripeSubscriptionId)
      setSubId(user.clientData.subscription.stripeSubscriptionId);
  }, []);

  const handleSubscribeClick = (plan) => {
    setSelectedPlan(plan);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedPlan(null);
  };

  const appearance = {
    theme: "stripe", // Other options: 'flat', 'night', 'none'
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#333",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
    rules: {
      ".Label": {
        color: "#6b7c93",
      },
    },
  };

  const options = {
    appearance,
  };

  const handleSuccess = () => {
    setIsCanceled(true);
    // Refresh or update the UI as needed
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <Row>
          <Col span={24}>
            <PackageOne
              plans={plans}
              onSubscribeClick={handleSubscribeClick}
              user={user}
            />
          </Col>
        </Row>
        {user?.clientData?.subscription?.stripeSubscriptionId ? (
          <Row>
            <Col span={20} style={{ align: "right", textAlign: "right" }}>
              <UpdatePaymentInformation user={user} />
            </Col>
            <Col span={4} style={{ align: "right", textAlign: "right" }}>
              <div>
                {!isCanceled && subId ? (
                  <CancelSubscriptionButton
                    subscriptionId={subId}
                    onSuccess={handleSuccess}
                    accessToken={user.accessToken}
                  />
                ) : (
                  <p>Subscription has been canceled.</p>
                )}
              </div>
            </Col>
          </Row>
        ) : null}
        <Modal
          style={{ padding: "50px" }}
          title="Complete or Update Subscription"
          open={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          {selectedPlan && (
            <StripeCheckoutForm
              plan={selectedPlan}
              user={user}
              onSuccess={handleModalCancel}
            />
          )}
        </Modal>
      </Elements>
    </>
  );
};

export default Pricing;
