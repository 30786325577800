import React from "react";

function Integrations(client) {
  console.log(client)
  return (
    <>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #ddd",
          fontSize: 12,
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                padding: "8px",
                width: "30%",
                backgroundColor: "#f0f0f0",
              }}
            >
              Integrations
            </td>
            <td style={{ border: "1px solid #ddd", padding: "8px" }}>
              {client?.client?.integrations?.toString() || 'None'}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default Integrations;
