import { Menu, Badge } from "antd";
import {
  UserOutlined,
  EditOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  ImportOutlined,
  RiseOutlined,
  SendOutlined,
  MailOutlined,
  RocketOutlined,
  CreditCardOutlined,
  ApiOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function MainMenu({ onMenuItemClick }) {
  // Receive the onMenuItemClick prop
  const { logout, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SHOP_URL;

  const handleMenuClick = (onClick) => {
    onClick(); // Navigate or perform menu action
    if (onMenuItemClick) onMenuItemClick(); // Close drawer if function is provided
  };

  const items = [
    {
      key: "/payees",
      icon: <UserOutlined />,
      label: "Payees",
      onClick: () => handleMenuClick(() => navigate("/payees")),
    },
    {
      key: "/payments",
      icon: <CreditCardOutlined />,
      label: "Payments",
      onClick: () => handleMenuClick(() => navigate("/payments")),
    },
    {
      key: "/fulfillment",
      icon: <RocketOutlined />,
      label: (
        <span style={{ position: "relative", display: "inline-block" }}>
          Fulfillment Orders
          <Badge
            count="New"
            color="#ff4757"
            style={{
              position: "absolute",
              top: "-15px",
              right: "-30px",
              fontSize: "10px",
              height: "15px",
              lineHeight: "15px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          />
        </span>
      ),
      onClick: () => handleMenuClick(() => navigate("/fulfillment")),
    },
    {
      key: "/file-import",
      icon: <ImportOutlined />,
      label: "Import Payments",
      
      children: [{
        key: "/file-import",
        icon: <FileExcelOutlined />,
        label: "File Import",
        onClick: () => handleMenuClick(() => navigate("/file-import")),
      },
      {
        key: "/integration-import",
        icon: <ApiOutlined />,
        label: "Integration Import",
        onClick: () => handleMenuClick(() => navigate("/integration-import")),
      },]
    },
   
    {
      key: "divider-1",
      type: "divider",
    },

    {
      key: "/shop",
      icon: <ShoppingCartOutlined />,
      label: "Buy Supplies",
      onClick: () => handleMenuClick(() => window.open(url, "_blank")),
    },
    {
      key: "/help",
      icon: <QuestionCircleOutlined />,
      label: "Help",
      onClick: () => handleMenuClick(() => navigate("/help")),
    },
    {
      key: "/settings",
      icon: <SettingOutlined />,
      label: "Settings",
      onClick: () => handleMenuClick(() => navigate("/settings")),
    },
    {
      key: "/logout",
      icon: <LogoutOutlined />,
      label: "Logout",
      onClick: () => handleMenuClick(logout),
    },
  ];

  if (user && user?.role === "employee") {
    items.push(
      {
        key: "divider-1",
        type: "divider",
      },
      {
        key: "/clients",
        icon: <UserOutlined />,
        label: "Clients",
        onClick: () => handleMenuClick(() => navigate("/clients")),
      },
      {
        key: "/fulfill-orders",
        icon: <RocketOutlined />,
        label: "Fulfillment Admin",
        onClick: () => handleMenuClick(() => navigate("/fulfill-orders")),
      },
      {
        key: "/marketing-hub",
        icon: <RiseOutlined />,
        label: "Marketing Hub",
        onClick: () => handleMenuClick(() => navigate("/marketing-hub")),
      }
    );
  }

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={["/file-import"]}
      style={{
        backgroundColor: "#dfe6e9",
        height: "100vh",
        paddingTop: "15px",
      }}
      selectedKeys={[location.pathname]}
      defaultSelectedKeys={["/payees"]}
      items={items}
    />
  );
}
