import { getFunctions, httpsCallable } from "firebase/functions";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase";

const functions = getFunctions();
export const setCustomClaims = async (uid, values) => {
  const setCustomClaimsFunction = httpsCallable(functions, "setCustomClaims");
  try {
    const result = await setCustomClaimsFunction({
      uid,
      ...values,
    });
    return result;
  } catch (error) {
    console.error("Error setting custom claims:", error);
  }
};

export const updateUser = async (uid, values) => {
  try {
    const userRef = doc(db, `users/${uid}`);
    await updateDoc(userRef, values);
  } catch (e) {
    console.log("error updating user ", e.message);
  }
};
