import { Card, Typography, Button } from 'antd'
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import IdentityCard from './IdentityCard'
import { useAuth } from '../../../contexts/AuthContext'
import { Divider } from 'antd';
import { DollarCircleOutlined, LogoutOutlined, RedoOutlined } from '@ant-design/icons';

function VerifyIndentification() {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && (user?.clientData?.verifications?.identity || user?.clientData?.verifications?.identityMeta?.status === 'pending' || user?.clientData?.verifications?.identityMeta?.status === 'processing' || user?.clientData?.verifications?.identityMeta?.status === 'completed')) {
            navigate('/payments');
        }
    }, [user]);

    const checkIdentityVerification = async () => {
        setLoading(true);
        window.location.reload();
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img className="shadow" src={'/images/logo/logo_with_background.svg'} alt="logo" style={{ width: "500px", marginTop: 20, borderRadius: "10px" }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <Card style={{ width: 500 }}>
                    <IdentityCard message="In order to keep your account secure and prevent fraudulent activity, we require you to verify your identity before continuing." />
                    <Divider />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p>If you already completed the verification process, please wait a couple of minutes and click the 'Continue' button below or logout and come back later.</p>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button loading={loading} className="button-gray" onClick={() => {
                                setLoading(true);
                                checkIdentityVerification();
                            }}><RedoOutlined /> Check Status & Continue</Button>
                            <Button loading={loading} className="button-yellow" style={{ marginLeft: 10 }} onClick={() => {
                                setLoading(true);
                                checkIdentityVerification();
                            }}><LogoutOutlined /> Logout & Come Back Later</Button>
                        </div>
                    </div>

                    {user?.clientData?.subscription !== "trial" &&
                        <>
                            <Divider />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <p className="error">If you wish to decline the verification process and wish to cancel your subscription, please click the button below.</p>
                                <Button loading={loading} className="button-red" onClick={() => {
                                    navigate('/subscription');
                                }}><DollarCircleOutlined /> Review Subscription</Button>
                            </div>
                        </>
                    }
                </Card>
            </div>
        </>
    )
}

export default VerifyIndentification