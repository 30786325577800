import { Button, Typography, Avatar, Layout, notification } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { getInitialsFullName } from "../utils/functions/strings";
import { useAuth } from "../contexts/AuthContext";
import { isTrialExpired } from "../utils/functions/checkTrialExpiration";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";


const { Header } = Layout;
const { Text } = Typography;


function TopHeader({ collapsed, setCollapsed, isMobile }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const checkTrialExpiration = async () => {
    if (user?.clientData?.subscription === "trial") {
      const expired = await isTrialExpired(
        user.clientId,
        user.clientData.trialExpiresOn.toDate()
      );
      if (expired) {
        navigate("/subscription");
      }
    }
  }
  function checkUserDisabled() {
    if (user?.clientData?.disabled || user?.clientData?.hasAccess === false) {
      notification.warning({
        message:
          "You account has been disabled. Please contact support at contact@simple-checks.com",
      });
      navigate("/logout");
    }
  }

  async function checkOnboarding() {
    if (user && !user.clientId) {
      // Process invitation onboarding or normal onboarding
      const invitesCollection = collection(db, "invites");
      const q = query(
        invitesCollection,
        where("email", "==", user.email)
      );

      const inviteSnap = await getDocs(q);
      if (!inviteSnap.empty) {
        navigate("/invitation-onboarding");
      } else {
        navigate("/onboarding");
      }

    }
  }

  async function checkMarkedForDeletion() {
    if (user?.clientData?.markedForDeletion) {
      //update client and remove marked for deletion 
      await updateDoc(doc(db, "clients", user.clientId), {
        markedForDeletion: false,
        markedForDeletionDate: null,
        markedForDeletionUser: null
      });
    }
  }

  const checkIdentityVerification = async () => {
    if (user?.clientData?.idVerificationRequired && !user?.clientData?.verifications?.identity &&
      (user?.clientData?.verifications?.identityMeta?.status !== 'completed' &&
        user?.clientData?.verifications?.identityMeta?.status !== 'processing' &&
        user?.clientData?.verifications?.identityMeta?.status !== 'pending')) {
      navigate("/verify-identification");

    }
  }

  useEffect(() => {
    checkTrialExpiration();
    checkIdentityVerification();
    checkUserDisabled();
    checkOnboarding();
    checkMarkedForDeletion();
  }, [user]);





  return (
    <Header
      className="top-header"
      style={{
        position: "fixed",
        top: 0,
        left: isMobile ? 0 : collapsed ? "80px" : "240px", // Full width on mobile
        width: isMobile ? "100%" : `calc(100% - ${collapsed ? "80px" : "240px"})`, // Full width on mobile
        transition: "left 0.2s, width 0.2s", // Smooth transition on sidebar toggle
        zIndex: 1000,
      }}
    >
      <div style={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{ fontSize: "16px", width: 64, height: 64, color: "white" }}
        />
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", width: "100%", marginRight: "16px" }}>
          <Text style={{ color: "white", marginRight: "12px", fontSize: "16px" }}>{user && user.displayName}</Text>

          {user?.photoUrl ? (
            <Avatar src={user.photoUrl} size={40} />
          ) : (
            <Avatar
              size={40}
              style={{ display: "inline-block" }} // Ensure avatar displays inline
            >
              <div style={{ marginTop: 4, marginRight: 2 }}>
                {getInitialsFullName(user?.displayName || "N/A")}
              </div>
            </Avatar>

          )}
        </div>
      </div>
    </Header>
  );
}

export default TopHeader;
