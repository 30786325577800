import React, { useState, useEffect } from "react";
import {
  convertToFirebaseTimestamp,
  formatDate,
} from "../../utils/functions/dates";
import { mailToFormatter } from "../../utils/functions/strings";
import {
  Card,
  Upload,
  Button,
  Tag,
  Table,
  Progress,
  Divider,
  Row,
  Col,
  Select,
  message,
  notification,
  Checkbox,
  Alert,
} from "antd";
import {
  UploadOutlined,
  DownloadOutlined,
  ImportOutlined,
  QuestionCircleOutlined,
  ApiOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import apiCall from "../../utils/functions/apiCall";
import { currencyFormatter } from "../../utils/functions/numbers";
import {
  fetchPayeeByExternalId,
  fetchPayeeByQuery,
  upsertPayee,
} from "../../services/payeeServices";
import { PaymentElement } from "@stripe/react-stripe-js";
import { upsertCheck } from "../../services/checkServices";
import { fetchCheckByQuery } from "../../services/checkServices";

const { Option } = Select;

const IntegrationImport = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [integrationSelected, setIntegrationSelected] = useState(null);
  const [accountSelected, setAccountSelected] = useState(null);
  const [integrationData, setIntegrationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [toBeImportedData, setToBeImportedData] = useState([]);
  const [importProgress, setImportProgress] = useState(0);

  useEffect(() => {
    if (user && accounts.length === 0) {
      fetchAccounts();
    }
  }, [user]);

  useEffect(() => {
    if (
      user &&
      (!user?.clientData?.integrations ||
        user?.clientData?.integrations?.length < 1)
    ) {
      notification.warning({
        message: "No integrations found",
        description: "Please add an integration to continue",
      });
      navigate("/settings/integrations");
    }
    console.clear()
  }, [user]);

  const fetchAccounts = async () => {
    try {
      const accountsSnapshot = await getDocs(
        collection(db, `clients/${user.clientId}/accounts`)
      );
      const accountsList = accountsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAccounts(accountsList);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const handlePullData = async () => {
    if (!integrationSelected || !accountSelected) {
      message.error("Please select both an integration and an account.");
      return;
    }

    setLoading(true);
    try {
      let url = "";
      if (integrationSelected === "quickbooks") {
        url = `/v1/integrations/quickbooks/pull-data`;
      }

      const response = await apiCall({
        url,
        method: "POST",
        body: {
          clientId: user.clientId,
        },
        bodyAccessToken: true,
      });

      // console.log(response.data.data);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.bills
      ) {
        const billData = await processBillData(response.data.data.bills);
        setTableData(billData);
        // setToBeImportedData(billData);
      } else {
        message.error("No bill data received.");
      }
      setImportProgress(0);
      message.success("Data pulled successfully!");
    } catch (error) {
      console.error("Error pulling data:", error);
      message.error("Failed to pull data. Please try again. If there's been over 3 months, try reconnecting the QuickBooks Online Integration in settings");
    } finally {
      setLoading(false);
    }
  };

  async function getPayeeOrInsert(VendorRef, VendorAddr, insert = false) {
    let payee = null;
    payee = await fetchPayeeByQuery(user, "externalId", VendorRef.value);
    // console.log("payee result", payee);
    if (!payee || payee?.length < 1) {
      // console.log("payee not found by externalId, searching by name");
      payee = await fetchPayeeByQuery(user, "payableTo", VendorRef.name);
    }

    if ((!payee || payee?.length < 1) && insert) {
      // console.log("payee not found by externalId or name, adding payee");
      const payeeData = {
        address: {
          address1: VendorAddr?.Line1 || "",
          address2: VendorAddr?.Line2 || "",
          city: VendorAddr?.City || "",
          state: VendorAddr?.CountrySubDivisionCode || "",
          zip: VendorAddr?.PostalCode || "",
        },
        companyName: VendorRef?.name || "",
        externalId: VendorRef?.value || "",
        source: integrationSelected,
        isDeleted: false,
        name: VendorRef?.name || "",
        payableTo: VendorRef?.name || "",
        status: "Active",
      };
      const rs = await upsertPayee(user, payeeData);
      // console.log("heres rs", rs);
    }
    return payee || null;
  }

  function mailingInfoAnalyzer(VendorAddr) {
    // Remove "Id" key from the object
    if (!VendorAddr) return;
    delete VendorAddr.Id;

    // Get the values of the remaining keys in order
    const lines = Object.keys(VendorAddr)
      .filter((key) => key.startsWith("Line")) // Only consider keys starting with "Line"
      .sort((a, b) => a.localeCompare(b)) // Ensure the keys are in sequential order
      .map((key) => VendorAddr[key]) // Get their values
      .filter((line) => line.trim() !== ""); // Filter out empty or whitespace-only lines

    // Return joined lines or null if no valid lines exist
    return lines.length > 0 ? lines.join("\n") : null;
  }

  async function processBillData(integrationData) {
    if (!integrationData) return;
    let insertPayee = true;
    const processedData = [];
    for (const paymentData of integrationData) {
      //find if payee exists
      const mailTo = mailingInfoAnalyzer(paymentData.VendorAddr);

      const payeeData = await getPayeeOrInsert(
        paymentData.VendorRef,
        paymentData.VendorAddr,
        false
      );

      if (payeeData && payeeData.length > 0) {
        // console.log(`payee ${payeeData.payableTo} with external ID found`);
        insertPayee = false;
      }

      //check data by external id, if so, set id so it doesn't duplicate
      const checkData = await fetchCheckByQuery(
        user,
        "externalId",
        paymentData.Id
      );

      if (checkData.id) {
        // console.log(
        //   "check with external ID " +
        //     checkData.externalId +
        //     " for " +
        //     checkData.amount +
        //     " to " +
        //     checkData.payableTo +
        //     " already exist. Skipping this check"
        // );
        continue;
      }

      // console.log("here is the payee information", payeeData);
      // console.log('heres the account selected', accountSelected)
      // console.log("heres the paymentData", paymentData);

      //get the value of accountSelected from accounts
      const accountSelectedData = accounts.find(
        (account) => account.id === accountSelected
      );

      const newCheck = {
        account: {
          accountNickname: accountSelectedData.accountNickname,
          id: accountSelectedData.id,
        },
        externalId: paymentData.Id || "",
        amount: paymentData.Balance
          ? currencyFormatter(paymentData.Balance)
          : "",
        checkNumber: "To be Printed",
        comments: paymentData.DocNumber
          ? `Ref Bill No. ${paymentData.DocNumber}`
          : "",
        date: convertToFirebaseTimestamp(paymentData.TxnDate),
        isDeleted: false,
        mailingInfo: mailTo ? mailTo : "",
        memo: paymentData.PrivateNote || "",
        payableTo: paymentData.VendorRef.name || "",
        source: integrationSelected,
        dateAdded: new Date(),
        dateModified: new Date(),
        lineItems: [],
        accountingRef: {
          id: paymentData?.APAccountRef?.value || "",
          name: paymentData?.APAccountRef?.name || "",
        },
        payee: {
          insertPayee: insertPayee,
          payableTo: paymentData.VendorRef.name || "",
          externalId: paymentData.VendorRef.value || "",
          companyName: paymentData.VendorRef.name || "",
          email: "",
          id: "",
          name: paymentData.VendorRef.name || "",
          phoneNumber: "",
        },
        status: "to be printed",
        toBePrinted: true,
        toBeImported: true,
      };

      processedData.push(newCheck);
    }
    return processedData;
  }

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const integrationOptions =
    user?.clientData?.integrations?.map((integration) => ({
      value: integration,
      label: capitalize(integration),
    })) || [];

  const accountOptions =
    accounts.map((account) => ({
      value: account.id,
      label: account.accountNickname,
    })) || [];

  const handleAccountChange = (value, record) => {
    const accountSelectedData = accounts.find(
      (account) => account.id === value
    );
    if (accountSelectedData) {
      setTableData((prev) =>
        prev.map((row) =>
          row.externalId === record.externalId
            ? {
                ...row,
                account: {
                  id: accountSelectedData.id,
                  accountNickname: accountSelectedData.accountNickname,
                },
              }
            : row
        )
      );
    }
  };

  // Import function with a progress bar
  const handleImport = async () => {
    let total = tableData.length;
    let count = tableData.filter((row) => row.toBeImported === true).length;
    if (total < 1)
      return notification.warning({ message: "Nothing to import" });
    let current = 1;
    // console.log(tableData);
    for (let i = 0; i < total; i++) {
      if (tableData[i].toBeImported !== true) continue;
      try {
        // await someImportFunction(tableData[i]);
        if (tableData[i].payee)
          // add payee ID to the payment payload
          delete tableData[i].toBeImported;

        //check once again if there's a payee because it might have been inserted
        const payeeData = await fetchPayeeByExternalId(
          tableData[i].payee.externalId,
          user
        );

        // console.log('heres payee data', payeeData)

        if (payeeData?.id) {
          tableData[i].payee.id = payeeData.id;
          tableData[i].payee.insertPayee = false;
        }

        if (tableData[i].payee.insertPayee) {
          delete tableData[i].payee.insertPayee;
          tableData[i].payee.isDeleted = false;
          tableData[i].payee.dateAdded = new Date();
          tableData[i].payee.dateModified = new Date();
          tableData[i].payee.status = "Active";
          tableData[i].payee.source = integrationSelected;

          const rs = await upsertPayee(user, tableData[i].payee);
          // console.log(rs,tableData[i].payee )
          if (!rs.id) throw new Error("not able to insert payee");
          tableData[i].payee.id = rs.id;
        }

        //insert check
        await upsertCheck(user, tableData[i]);

        // If import is successful
        setTableData((prev) =>
          prev.map((row, index) =>
            index === i
              ? {
                  ...row,
                  importMeta: { success: true, errorMessage: "" },
                }
              : row
          )
        );
      } catch (error) {
        // If an error occurs during import
        setTableData((prev) =>
          prev.map((row, index) =>
            index === i
              ? {
                  ...row,
                  importMeta: { success: false, errorMessage: error.message },
                }
              : row
          )
        );
      }

      // Update progress after each import
      setImportProgress(Math.round((current / count) * 100));
      current++;
    }
  };

  // Toggles the `toBeImported` flag for a row
  const handleCheckboxChange = (checked, record) => {
    // console.log("record", record);
    setTableData((prev) =>
      prev.map((row) =>
        row.externalId === record.externalId
          ? { ...row, toBeImported: checked }
          : row
      )
    );
  };

  return (
    <>
      <h1 className="monospace">
        <ApiOutlined /> Integration Payment Import
      </h1>
      <Card>
        <Row gutter={16}>
          <Col span={6}>
            <Select
              placeholder="Select Integration"
              style={{ width: "100%" }}
              options={integrationOptions}
              onChange={setIntegrationSelected}
              value={integrationSelected}
            />
          </Col>
          <Col span={6}>
            <Select
              placeholder="Select Default Bank Account"
              style={{ width: "100%" }}
              options={accountOptions}
              onChange={setAccountSelected}
              value={accountSelected}
            />
          </Col>
          <Col span={4}>
            <Button
              className="button-blue"
              block
              icon={<DownloadOutlined />}
              onClick={handlePullData}
              loading={loading}
            >
              Pull Data
            </Button>
          </Col>
          <Col span={4}>
            <Button
              className="button-gray"
              block
              icon={<SettingOutlined />}
              onClick={() => navigate("/settings/integrations")}
              loading={loading}
            >
              Settings
            </Button>
          </Col>
          <Col span={4}>
            <Button
              block
              className="button-yellow"
              icon={<QuestionCircleOutlined />}
              loading={loading}
            >
              Instructions
            </Button>
          </Col>
        </Row>
        <Divider />

        <Row>
          {tableData.length > 0 && (
            <>
              <Row>
                <Button
                  className="button-green"
                  onClick={handleImport}
                  style={{ marginTop: 16 }}
                >
                  Start Import
                </Button>{" "}
              </Row>
            </>
          )}
          {importProgress > 0 && (
            <Progress
              percent={importProgress}
              style={{ marginTop: 16 }}
              status={importProgress === 100 ? "success" : "active"}
            />
          )}
        </Row>

        <Table
          dataSource={tableData}
          columns={[
            // {
            //   title: "Check Number",
            //   dataIndex: "checkNumber",
            //   key: "checkNumber",
            //   render: (text) => text || "New",
            // },
            {
              title: "Select",
              dataIndex: "toBeImported",
              key: "toBeImported",
              render: (checked, record) => (
                <Checkbox
                  checked={record.toBeImported}
                  key={record.externalId}
                  onChange={(e) =>
                    handleCheckboxChange(e.target.checked, record)
                  }
                />
              ),
            },
            {
              title: "Payable To",
              dataIndex: "payableTo",
              key: "payableTo",
            },
            {
              title: "Mailing Info",
              dataIndex: "mailingInfo",
              key: "mailingInfo",
            },
            {
              title: "Payee Found",
              dataIndex: "payee",
              key: "payee",
              render: (payee) =>
                payee.insertPayee ? "No (Will Insert)" : "Yes",
            },
            {
              title: "Date",
              dataIndex: "date",
              key: "date",
              render: (date) => `${formatDate(date)}`,
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              render: (text) => `$${currencyFormatter(text)}`,
            },
            { title: "Memo", dataIndex: "memo", key: "memo" },
            {
              title: "Bank Account",
              dataIndex: "bankAccount",
              key: "bankAccount",
              render: (text, record) => (
                <Select
                  value={record.account.id}
                  onChange={(value) => handleAccountChange(value, record)}
                  style={{ width: 200 }}
                >
                  {accounts.map((account) => (
                    <Option key={accounts.id} value={account.id}>
                      {account.accountNickname}
                    </Option>
                  ))}
                </Select>
              ),
            },
            {
              title: "Import Status",
              dataIndex: "importMeta",
              key: "importMeta",
              render: (importMeta) => {
                if (!importMeta) return null;
                return importMeta && importMeta.success ? (
                  <Tag className="bg-success-light success">Success</Tag>
                ) : (
                  <Tag className="bg-error-light error">Error</Tag>
                );
              },
            },
            {
              title: "Import Error",
              dataIndex: "importMeta",
              key: "importMetaError",
              render: (importMeta) => {
                return importMeta?.errorMessage || "";
              },
            },
          ]}
          style={{ marginTop: 16 }}
        />
      </Card>
    </>
  );
};

export default IntegrationImport;
