import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../../../firebase";
import {
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
  deleteDoc,
  query,
  collection,
  getDocs,
  where,
} from "firebase/firestore";
import {
  Form,
  Input,
  Button,
  Card,
  Typography,
  Spin,
  Select,
  Checkbox,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { updateProfile } from "firebase/auth";
// import axios from "axios";
import  apiCall from "../../../utils/functions/apiCall";

const { Title } = Typography;

const InvitationOnboarding = () => {
  const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT === "dev";
  const { user, invitation } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { Option } = Select;

  const [form] = Form.useForm(); // Initialize form instance

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.clientId) {
        navigate(process.env.REACT_APP_STARTING_PAGE || "/");
      }
      if (user && invitation) {
        form.setFieldsValue({
          displayName: invitation?.name || "",
          companyName: invitation?.clientName || "",
          address: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          email: invitation?.email || "",
        });
      }
    };

    if (user) fetchUserData();
  }, [user, invitation, form, navigate]);

  const functions = getFunctions();
  const setCustomClaims = async (uid, clientId, role) => {
    const setCustomClaimsFunction = httpsCallable(functions, "setCustomClaims");

    try {
      const result = await setCustomClaimsFunction({ uid, clientId, role });
      if (DEBUG_MODE) console.log(result.data.message);
    } catch (error) {
      console.error("Error setting custom claims:", error);
    }
  };

  const handleSubmit = async (values) => {
    if (!values.displayName.includes(" ")) {
      alert("Please enter your full name.");
      return;
    }
    setLoading(true);

    try {
      console.log(user);
      console.log(invitation);
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        ...values,
        isAdmin: false,
        role: "user",
        isDeleted: false,
        clientId: invitation.clientId,
        dateAdded: serverTimestamp(),
        dateModified: serverTimestamp(),
      });
      await setCustomClaims(user.uid, invitation.clientId, "user");

      const clientRef = doc(db, "clients", invitation.clientId);
      await updateDoc(clientRef, {
        users: arrayUnion({
          userId: user.uid,
          name: values.displayName,
          email: values.email,
          role: "user",
        }),
        userIds: arrayUnion(user.uid), // Appending user.uid to the userIds array
      });

      //hard delete invitation from /invites collection
      deleteInviteByEmail(user.email);

      await updateProfile(user, {
        name: values.displayName,
      });

      //send welcome email
      try {
        const url = `/v1/users/${user.uid}/send-email`;

        const response = await apiCall({
          url, // URL to send the POST request
          method: "POST",
          body: { purpose: "welcome" },
        });
        console.log("Email sent successfully:", response.data);
      } catch (error) {
        console.error(
          "Error sending welcome email:",
          error.response ? error.response.data : error.message
        );
      }

      setLoading(false);
      notification.success({
        message: "Saved",
        content:
          "We have now associated your account with your company. Please log back in to continue",
      });
      navigate("/logout");
    } catch (error) {
      setLoading(false);
      console.error("Error updating user data:", error.message);
    }
  };

  const deleteInviteByEmail = async (email) => {
    const q = query(collection(db, "invites"), where("email", "==", email));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => deleteDoc(doc.ref));
  };

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        style={{
          width: 400,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Title level={2}>Onboarding</Title>
        </div>
        <Form form={form} name="user_form" onFinish={handleSubmit}>
          <Form.Item name="email">
            <Input placeholder="Email" disabled />
          </Form.Item>
          <Form.Item
            name="displayName"
            rules={[{ required: true, message: "Full name is required" }]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item name="companyName">
            <Input
              placeholder="Company Name (Optional)"
              disabled={!!invitation?.clientName}
            />
          </Form.Item>
          <Form.Item
            name="address"
            rules={[{ required: true, message: "Please enter your address" }]}
          >
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            name="state"
            rules={[{ required: true, message: "Please enter your state" }]}
          >
            <Select placeholder="Select a state">
              {states.map((state) => (
                <Option key={state} value={state}>
                  {state}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="zip"
            rules={[{ required: true, message: "Please enter your ZIP Code" }]}
          >
            <Input placeholder="ZIP Code" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "Please enter your phone number" },
            ]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>
          <Form.Item
            name="acceptTermsConditions"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "You must agree to terms and conditions to proceed"
                        )
                      ),
              },
            ]}
          >
            <Checkbox>
              I agree with{" "}
              <Link
                to="https://simple-checks.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </Link>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default InvitationOnboarding;
