import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

import CryptoJS from "crypto-js";

export function generateSecretKey(length = 64) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode(...array));
}

export async function decryptAccounts(accounts) {
  if (accounts && accounts.length > 0) {
    try {
      const decryptedValues = await Promise.all(
        accounts.map(async (account) => {
          const decryptFunction = httpsCallable(functions, "decryptData");
          const decryptedAccountInfo = await decryptFunction({
            encryptedText: account.encryptedAccount,
          });
          const { encryptedAccount, ...restOfAccount } = account;
          return {
            ...restOfAccount,
            account_info: JSON.parse(decryptedAccountInfo.data.plainText),
          };
        })
      );
      return decryptedValues;
    } catch (e) {
      console.error(e.message);
    }
  }
}

//MAKS AND UNMASK FUNCTIONS NEVER CHANGE THIS
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

// Function to mask (encode) data
export function maskData(data) {
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  const base64Encoded = btoa(data); // Base64 encode
  const customEncoded = base64Encoded.replace(/=/g, `:${PUBLIC_KEY}`);
  return customEncoded;
}

// Function to unmask (decode) data
export async function unmaskData(maskedData) {
  const base64Restored = await maskedData.replace(
    new RegExp(`:${PUBLIC_KEY}`, "g"),
    "="
  );
  const originalData = await atob(base64Restored); // Base64 decode
  return originalData;
}
