import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Input,
  Button,
  Row,
  Col,
  Form,
  Spin,
  Select,
  notification,
  Popconfirm,
  Checkbox,
  Alert,
  Tag,
  Tooltip,
} from "antd";
import { formatDate } from "../../utils/functions/dates";
import {
  EditOutlined,
  FilterOutlined,
  RocketOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { decryptAccounts } from "../../utils/functions/encryption";
import apiCall from "../../utils/functions/apiCall";
import { useNavigate, Link } from "react-router-dom";

const { Option } = Select;

const FulfillmentModal = ({
  setFulfillmentModalOpen,
  fulfillmentModalOpen,
  selectedChecksToPrint,
  startingCheckNumber,
  setStartingCheckNumber,
  setSelectedChecksToPrint,
  user,
  accounts,
  resetFilteredChecks,
  fetchChecks,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [checksData, setChecksData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [decAccounts, setDecAccounts] = useState([]);
  const [pricingLoading, setPricingLoading] = useState(false);
  const [totalCost, setTotalCost] = useState(0);

  // Fetch pricing options when the component mounts
  useEffect(() => {
    const fetchPricingOptions = async () => {
      setPricingLoading(true);
      const url = "/v1/fulfillment/pricing-options";

      try {
        // const response = await fetch(url, {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${user.accessToken}`, // Add the Bearer token here
        //   },
        // });

        const response = await apiCall({
          url,
        });

        if (!response.data) {
          throw new Error("Failed to fetch pricing options.");
        }

        const { data } = response.data;
        // console.log(data);

        // Transform `shipping` object into an array of options
        const options = Object.entries(data.shipping).map(([key, value]) => ({
          key,
          ...value,
        }));

        setPricingOptions(options); // Ensure pricingOptions is an array
      } catch (error) {
        console.error("Error fetching pricing options:", error);
        notification.error({ message: "Failed to fetch fulfillment pricing." });
        setPricingOptions([]); // Ensure it's an empty array on error
      } finally {
        setPricingLoading(false);
      }
    };

    fetchPricingOptions();
  }, [user]);

  useEffect(() => {
    if (fulfillmentModalOpen) {
      setChecksData(
        selectedChecksToPrint
          .map((check) => {
            // Find matching account and check for signature
            const matchingAccount = decAccounts.find(acc => acc.id === check.account?.id);
            // console.log(matchingAccount)
            if (!matchingAccount?.account_info?.signature) {
              return null;
            }

            return {
              ...check,
              address1: check.payee?.address?.address1 || "",
              address2: check.payee?.address?.address2 || "",
              city: check.payee?.address?.city || "",
              state: check.payee?.address?.state || "",
              zip: check.payee?.address?.zip || "",
            };
          })
          .filter(Boolean) // Remove null entries
      );
      form.setFieldsValue({ startingCheckNumber });
    }
  }, [fulfillmentModalOpen, selectedChecksToPrint, decAccounts]);




  useEffect(() => {
    const decryptAccountsData = async () => {
      if (accounts && accounts?.length > 0 && decAccounts?.length === 0) {
        try {
          const result = await decryptAccounts(accounts);
          setDecAccounts(result);
        } catch (error) {
          console.error("Error decrypting accounts:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    form.setFieldsValue({ startingCheckNumber });
    decryptAccountsData();
  }, [accounts, decAccounts?.length]);

  const handleCheckboxChange = (record) => {
    setSelectedChecksToPrint((prevSelected) => {
      const isSelected = prevSelected?.some((item) => item.id === record.id);
      if (isSelected) {
        return prevSelected.filter((item) => item.id !== record.id); // Remove if already selected
      } else {
        return [...prevSelected, record]; // Add if not selected
      }
    });
  };

  const columns = [
    {
      title: "select",
      key: "select",
      render: (text, record) => {
        const account = decAccounts?.find(
          (acc) => acc.id === record.account.id
        );

        if (loading) return <Spin />;

        if (!account || !account.account_info.signature) {
          return (
            <span className="error" style={{ width: "300px" }}>
              Account <strong>{account?.accountNickname || "N/A"}</strong> lacks
              a signature. Go to the bank account tab in settings to add one
              before using this feature.
            </span>
          );
        }
        return (
          <Checkbox
            checked={selectedChecksToPrint?.some(
              (item) => item.id === record.id
            )}
            onChange={() => handleCheckboxChange(record)}
          />
        );
      },
    },
    { title: "Check Number", dataIndex: "checkNumber", key: "checkNumber" },
    {
      title: "Account",
      key: "accountNickname",
      render: (text, record) => record.account?.accountNickname || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    {
      title: "Mailing Info",
      dataIndex: "mailingInfo",
      key: "mailingInfo",
      render: (text, record) =>
        text ? (
          <>
            <Link to={`/payments/${record.id}`}>
              <Tooltip title="Edit Mailing Info">
                <Tag
                  className="bg-gray-light gray"
                  style={{ paddingTop: 3, paddingBottom: 3 }}
                >
                  <EditOutlined />
                </Tag>
              </Tooltip>
            </Link>

            {text?.length < 20 && (
              <Tooltip title="Address May Be Incomplete">
                <Tag
                  className="bg-warning-light warning"
                  style={{ paddingTop: 3, paddingBottom: 3 }}
                >
                  <WarningOutlined />
                </Tag>
              </Tooltip>
            )}

            <span className={text?.length < 20 ? "error" : ""}>{text}</span>
          </>
        ) : (
          <>
            <Link to={`/payments/${record.id}`}>
              <Tooltip title="Edit Mailing Info">
                <Tag
                  className="bg-gray-light gray"
                  style={{ paddingTop: 3, paddingBottom: 3 }}
                >
                  <EditOutlined />
                </Tag>
              </Tooltip>
            </Link>
            <span className="error">
              Mailing info missing. Edit check to add
            </span>
          </>
        ),
    },

    { title: "Check Memo", dataIndex: "memo", key: "memo" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  function calculateTotalCost(newPricing) {
    const pricing = newPricing || selectedPricing; // Use passed option or fallback to state
    setTotalCost((pricing?.pricing || 0) * (checksData?.length || 0));
    // console.log(
    //   "Calculating",
    //   (pricing?.pricing || 0) * (checksData?.length || 0)
    // );
  }

  // const updateCheckField = (index, field, value) => {
  //   setChecksData((prev) => {
  //     const updated = [...prev];
  //     updated[index][field] = value;
  //     return updated;
  //   });
  // };

  const handleSubmit = async () => {
    for (const check of checksData) {
      if (!check?.mailingInfo || check?.mailingInfo === "") {
        notification.error({
          message:
            "You are trying to submit checks with missing mailing information. Please edit the check, add mailing information, and try again.",
        });
        return; // Exits the handleSubmit function entirely
      }

      if (!check?.account?.id || check?.account?.id === "") {
        notification.error({
          message: `Check of ${check.amount} for ${check.payableTo} is missing a default bank account. Refresh page, edit check, select bank account and try again.`,
        });
        return; // Exits the handleSubmit function entirely
      }
    }



    const payload = {
      userId: user.uid,
      userEmail: user.email,
      checks: checksData,
      shippingService: selectedPricing?.key || "regular",
      // unitCost: selectedPricing?.unitCost || 0,
      startingCheckNumber,
      totalChecks: checksData.length,
      // totalCost,
      status: "pending",
    };

    setSubmitting(true);
    const url = "/v1/fulfillment/";
    try {
      const response = await apiCall({
        url,
        method: "POST",
        body: payload,
      });

      if (response.status !== 200 && response.status !== 500) {
        const responseBody = await response.data;
        const errorMessage = responseBody.message || "An error occurred"; // Extract the message or provide a fallback
        throw new Error(errorMessage);
      } else if (response.status !== 200) {
        throw new Error("Failed to submit fulfillment.");
      }

      notification.success({
        message: "Fulfillment Submitted",
        description: `Successfully submitted ${checksData.length} checks.`,
      });
      setFulfillmentModalOpen(false);
    } catch (error) {
      console.error("Error submitting fulfillment:", error);
      notification.error({ message: error.message });
    } finally {
      fetchChecks();
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title="Send to Fulfillment"
      open={fulfillmentModalOpen}
      footer={false}
      closable={false}
      style={{ minWidth: "90%", marginTop: -70 }}
      onCancel={() => setFulfillmentModalOpen(false)}
      maskClosable={false}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Starting Check Number"
              name="startingCheckNumber"
              rules={[
                { required: true, message: "Enter the starting check number" },
              ]}
            >
              <Input
                value={startingCheckNumber}
                onChange={(e) => setStartingCheckNumber(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item label="Shipping Service">
              <Select
                placeholder="Select shipping service"
                onChange={(value) => {
                  const selectedOption = (pricingOptions || []).find(
                    (option) => option.key === value
                  );
                  setSelectedPricing(selectedOption); // Update the selected pricing
                  calculateTotalCost(selectedOption); // Pass the new option directly to calculateTotalCost
                }}
                disabled={pricingLoading}
              >
                {(pricingOptions || [])
                  .sort((a, b) => a.pricing - b.pricing) // Sort by pricing in ascending order
                  .map((option) => (
                    <Option key={option.key} value={option.key}>
                      {`${option.carrier} | ${option.serviceName} | $${option.pricing} per check`}
                    </Option>
                  ))}
              </Select>
              {pricingLoading && <Spin />}
            </Form.Item>
          </Col>
        </Row>
        {selectedPricing && (
          <Row>
            <div
              className="bg-warning-light"
              style={{ padding: 4, borderRadius: 4, width: "100%" }}
            >
              <Col xs={24}>
                <Row gutter={[8, 4]}>
                  <Col xs={12} lg={6}>
                    <strong>Carrier:</strong> {selectedPricing.carrier}
                  </Col>
                  <Col xs={12} lg={6}>
                    <strong>Service:</strong> {selectedPricing.serviceName}
                  </Col>
                  <Col xs={12} lg={6}>
                    <strong>Timeframe:</strong>{" "}
                    {selectedPricing.carrierTimeframe}
                  </Col>
                  <Col xs={12} lg={6}>
                    <strong>Fulfillment Timeframe:</strong>{" "}
                    {selectedPricing.fulfillmentTimeframe}
                  </Col>
                  <Col xs={12} lg={6}>
                    <strong>Cost/Check:</strong> ${selectedPricing.pricing}
                  </Col>
                  <Col xs={12} lg={6}>
                    <strong>Total:</strong> $
                    {totalCost.toFixed(2) + " + sales tax if you are in FL"}
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        )}
      </Form>
      <Button
        onClick={resetFilteredChecks}
        className="button-gray"
        style={{ marginTop: 10 }}
      >
        <FilterOutlined />
        Reset Filter
      </Button>

      <Table
        rowKey="id"
        style={{ marginTop: 20 }}
        dataSource={checksData}
        columns={columns}
        pagination={false}
      />

      <Row style={{ marginTop: 15 }} gutter={16}>
        <Col span={12}>
          <Button
            onClick={() => setFulfillmentModalOpen(false)}
            disabled={submitting}
          >
            Cancel
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Popconfirm
            title="Do you confirm all of the mailing information above is complete and correct?"
            onConfirm={handleSubmit}
            okText="Proceed"
            cancelText="Back"
          >
            <Button
              type="primary"
              disabled={!selectedPricing || submitting}
              loading={submitting}
            >
              <RocketOutlined /> Submit to Fulfillment
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </Modal>
  );
};

export default FulfillmentModal;
