import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Accounts from "./Accounts";
import Checks from "./Checks";
import Details from "./Details";
import Users from "./Users";
import Integrations from "./Integrations";
import { db, functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import {
  doc,
  updateDoc,
  collection,
  getDocs,
  setDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import {
  Table,
  Button,
  Card,
  Row,
  Col,
  notification,
  message,
  Alert,
} from "antd";
import { useAuth } from "../../../contexts/AuthContext";
import apiCall from "../../../utils/functions/apiCall";
import { setCustomClaims, updateUser } from "../../../services/userServices";
import { formatDate } from "../../../utils/functions/dates";

function ClientDetails() {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [checks, setChecks] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const CLIENT_COLLECTION = "clients";
  const ACCOUNT_SUBCOLLECTION = "accounts";
  const CHECK_SUBCOLLECTION = "checks";
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);

        // Fetch all data in parallel and ensure it resolves
        const [clientData, accountData, checkData] = await Promise.all([
          fetchClientDetails(),
          fetchAccountDetails(),
          fetchCheckDetails(),
        ]);

        // Set local state for each dataset
        setClient(clientData);
        setAccounts(accountData);
        setChecks(checkData);
        setUsers(clientData?.users || []);

        // Pass fetched data to verifyAndUpdateCounts
        await verifyAndUpdateCounts(
          clientData,
          accountData,
          checkData,
          clientData?.users || []
        );
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  async function fetchCheckDetails() {
    const checksRef = collection(db, `clients/${clientId}/checks`);
    const queryChecks = query(checksRef, where("isDeleted", "==", false));
    const snapshot = await getDocs(queryChecks);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  async function fetchAccountDetails() {
    const accountsRef = collection(db, `clients/${clientId}/accounts`);
    const queryAccounts = query(accountsRef, where("isDeleted", "==", false));
    const snapshot = await getDocs(queryAccounts);
    const encryptedData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const decryptedValues = await Promise.all(
      encryptedData.map(async (account) => {
        const decryptedAccountInfo = await handleDecrypt(
          account.encryptedAccount
        );
        const { encryptedAccount, ...restOfAccount } = account;
        return { ...restOfAccount, account_info: decryptedAccountInfo };
      })
    );

    return decryptedValues;
  }

  async function fetchClientDetails() {
    const docRef = doc(db, "clients", clientId);
    const docSnap = await getDoc(docRef);
    return docSnap.data() || {};
  }

  const handleDecrypt = async (encryptedValue) => {
    const decryptFunction = httpsCallable(functions, "decryptData");
    const decrypted = await decryptFunction({
      encryptedText: encryptedValue,
      clientId,
    });
    return JSON.parse(decrypted.data.plainText);
  };

  async function verifyAndUpdateCounts(
    clientData = {},
    accountData = [],
    checkData = [],
    userData = []
  ) {
    const accountsCount = accountData.length;
    const accountsPendingReviewCount = accountData.filter(
      (account) => account.status === "pre-approved"
    ).length;
    const checksCount = checkData.length;
    const userCount = userData.length;

    // Reference to the Firestore document
    const clientRef = doc(db, "clients", clientId);

    // Update Firestore document with the latest counts
    await updateDoc(clientRef, {
      accountsCount,
      accountsPendingReviewCount,
      checksCount,
      userCount,
    });

    // Update local state for client
    setClient((prevClient) => ({
      ...prevClient,
      accountsCount,
      accountsPendingReviewCount,
      checksCount,
      userCount,
    }));

    message.success("Client counts refreshed.");
  }

  async function impersonate() {
    const setCustomClaimsFunction = httpsCallable(functions, "setCustomClaims");

    setLoading(true);
    const role = "employee";
    try {
      const rs = await setCustomClaimsFunction({
        uid: user.uid,
        role: role,
        clientId: clientId,
      });
      await updateUser(user.uid, { clientId });
      window.location.href = "/logout";
    } catch (e) {
      notification.error({
        message: `Error setting the user to impersonate this account: ${e.message}`,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <h1 className="monospace">Client Details</h1>
      {client?.markedForDeletion && (
        <Alert
          type="error"
          showIcon={true}
          message={`This client account has been marked for deletion. Ensure subscription is canceled and delete this client 30 days after ${formatDate(
            client.markedForDeletionDate
          )}`}
          style={{ marginBottom: 15 }}
        />
      )}
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Card
            style={{ minHeight: "200px", maxHeight: 600, overflowY: "auto" }}
            title="Account Details"
            type="inner"
            loading={loading}
          >
            <Row gutter={16}>
              <Button
                onClick={() => navigate("/clients")}
                loading={loading}
                className="button-gray"
              >
                Back
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                loading={loading}
                onClick={() => impersonate()}
                className="button-yellow"
              >
                Impersonate
              </Button>
            </Row>
            <Row gutter={16}>
              {client && (
                <Details
                  client={client}
                  clientId={clientId}
                  loading={loading}
                />
              )}
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            style={{ minHeight: "200px", maxHeight: 600, overflowY: "auto" }}
            loading={loading}
            title="Users"
            type="inner"
          >
            {client && <Users users={client.users} />}
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            style={{ minHeight: "200px", maxHeight: 600, overflowY: "auto" }}
            loading={loading}
            title="Integrations"
            type="inner"
          >
            <Col span={24}>
              <Integrations />
            </Col>
          </Card>
        </Col>
        <Col span={24}>
          <Card
            style={{ minHeight: "300px", marginTop: 20 }}
            loading={loading}
            title="Bank Accounts"
            type="inner"
          >
            {accounts && (
              <Accounts
                accounts={accounts}
                clientId={clientId}
                setAccounts={setAccounts}
              />
            )}
          </Card>
        </Col>
        <Col span={24}>
          <Card
            style={{ minHeight: "300px", marginTop: 20 }}
            loading={loading}
            title="Payments"
            type="inner"
          >
            <Checks checks={checks} />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ClientDetails;
