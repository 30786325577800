import moment from "moment";
import { Timestamp } from "firebase/firestore";

export function formatDate(timestamp) {
  if (!timestamp) {
    return "Invalid Date";
  }

  let date;

  // Handle Firestore Timestamp Object
  if (typeof timestamp._seconds === "number") {
    date = new Date(timestamp._seconds * 1000);
  }
  // Handle Timestamp Object with 'seconds'
  else if (typeof timestamp.seconds === "number") {
    date = new Date(timestamp.seconds * 1000);
  }
  // Handle JavaScript Date Object
  else if (timestamp instanceof Date) {
    date = timestamp;
  }
  // Handle ISO String
  else if (typeof timestamp === "string" && !isNaN(Date.parse(timestamp))) {
    date = new Date(timestamp);
  }
  // Handle UNIX Epoch (in milliseconds)
  else if (typeof timestamp === "number" && timestamp > 0) {
    date = new Date(timestamp);
  }
  // Invalid format
  else {
    return "Invalid Date";
  }

  return moment(date).format("MM/DD/YYYY");
}

export function convertToFirebaseTimestamp(dateString) {
  // Parse the YYYY-MM-DD string into a JavaScript Date object
  const date = new Date(dateString);

  // Check for invalid date
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format. Ensure it is YYYY-MM-DD.");
  }

  // Convert the Date object to a Firebase Timestamp
  return Timestamp.fromDate(date);
}
