import React, { useState } from "react";
import { Row, Col, Typography, Tag, Button } from "antd";
import { IdcardOutlined, LockOutlined } from "@ant-design/icons";
import { useAuth } from "../../../contexts/AuthContext";
import apiCall from "../../../utils/functions/apiCall";
import { notification } from "antd";

function IdentityCard({ message }) {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  async function handleVerifyId() {
    setLoading(true);
    try {
      // Make the API call to your backend
      const response = await apiCall({
        url: "/v1/identity", // The endpoint you're calling
        method: "POST", // Assuming a POST request
        body: { clientId: user.clientId }, // Pass the clientId in the body
        authenticated: true, // Use Firebase authentication
      });

      // Check if verificationUrl exists in the response
      if (response.data.success && response.data.verificationUrl) {
        // Redirect to the verification URL
        window.location.href = response.data.verificationUrl;
      } else {
        // Show error notification if no verificationUrl
        notification.error({
          message: "Verification Failed",
          description: "Failed to initiate ID verification. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error initiating ID verification:", error);
      // Show error notification if an error occurs
      notification.error({
        message: "Verification Error",
        description: "An error occurred during the ID verification process.",
      });
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        <Col xs={24} sm={24}>
          {/* <Card style={{ height: "500px", overflow: "auto" }}> */}
          <Row
            className="bg-info-light"
            style={{
              borderRadius: 10,
              padding: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IdcardOutlined className="info" style={{ fontSize: "3rem" }} />
          </Row>
          <Row>
            <Col xs={24} style={{ textAlign: "center", marginTop: -10 }}>
              <Typography.Title level={5}>
                Compliance: Identity Verification
              </Typography.Title>
              <Tag color="success">Instant Validation</Tag>
              {/* <Tag color="blue">Easier</Tag> */}
              <Typography.Paragraph style={{ marginTop: 10 }}>
                {message ? message : "Before adding a Bank Account, to ensure safety and help prevent" +
                  "any fraudulent activity, we require you to verify your identity." +
                  "This is a quick one-time process that takes a couple of minutes." +
                  "You will need a government ID and your mobile phone handy."}
              </Typography.Paragraph>
            </Col>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  handleVerifyId();
                }}
                loading={loading}
              >
                <IdcardOutlined /> Verify My Identity
              </Button>
            </Row>
            <Row gutter={[16, 16]} style={{ width: "100%" }}>
              <Col xs={24} style={{ marginTop: 30 }}>
                <Typography.Paragraph className="success" style={{ textAlign: 'center' }}>
                  <LockOutlined /> This information is secure and encrypted
                </Typography.Paragraph>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default IdentityCard;
