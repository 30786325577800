// import { db } from "../../firebase";
// import { setDoc, doc } from "firebase/firestore";
import apiCall from "../../utils/functions/apiCall";

export async function checkMetaUpsert(user, check) {
  // console.log("check:", check);

  if (!check || !check.id) {
    console.error("Invalid check object or missing ID:", check);
    return;
  }

  const url = `/v1/checks/${check.id}/metaCheck`;
  const token = user.accessToken;
  const clientId = user.clientId;


  try {
    //async
    // .post(
    //   url,
    //   { ...check },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );

    apiCall({
      url: url,
      method: "POST",
      body: { ...check },
    });
    //   // Define a unique document ID, e.g., using the check ID
    //   const docId = check.id;

    //   // Create a reference to the document
    //   const docRef = doc(db, "metaChecks", docId);

    //   // Upsert the document (create or update)
    //   await setDoc(
    //     docRef,
    //     {
    //       id: docId,
    //       payableTo: check.payableTo,
    //       payeeId: check.payee?.id || null,
    //       clientId: user.clientId,
    //       dateAdded: check.dateAdded || Date.now(),
    //       createdBy: {
    //         id: check.createdBy?.id || user.uid,
    //         name: check.createdBy?.name || user.displayName,
    //       },
    //       date: check.date,
    //       status: check.status,
    //       amount: check.amount,
    //       isDeleted: check.isDeleted || false,
    //       toBePrinted: check.toBePrinted || false,
    //       checkNumber: check.checkNumber,
    //       printDate: check.printDate || null,
    //       dateModified: check.dateModified || Date.now(),
    //       imported: check.imported || false,
    //       editedBy: {
    //         id: check.editedBy?.id || user.uid,
    //         name: check.editedBy?.name || user.displayName,
    //       },
    //     },
    //     { merge: true }
    //   );

    // console.log("Check metadata upserted successfully");
  } catch (e) {
    console.error("Error upserting check metadata: ", e.message);
  }
}
