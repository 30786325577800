import React from "react";
import { Modal } from "antd";

function IntegrationSyncModal({
  integrationModalOpen,
  setIntegrationModalOpen,
}) {
  function onClose() {
    setIntegrationModalOpen(false);
  }

  return (
    <Modal
      title="Select Checks to Print"
      open={integrationModalOpen}
      okText="Generate Checks"
      onOk={onClose}
      onCancel={onClose}
      //   onOk={() => {
      //     if (!startingCheckNumber) {
      //       notification.error({ message: "Enter starting check number" });
      //       return;
      //     }

      //     const selectedTemplate = form.getFieldValue("template");
      //     if (selectedTemplate === undefined || selectedTemplate === null) {
      //       notification.warning({ message: "Please select a check template" });
      //       return;
      //     }

      //     onOk();
      //   }}
      //   onCancel={onCancel}
      //   style={{
      //     minWidth: "90%",
      //     marginTop: isMobile ? 10 : -70,
      //     minHeight: "80vh",
      //     minHeight: "100vh",

      //     overflowY: "auto",
      //     marginTop: "-70",
      //   }
      //   }
      maskClosable={false}
    >
      value
    </Modal>
  );
}

export default IntegrationSyncModal;
