import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Checks from "./Checks";
import { db, functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { DisplaySignature } from "../../../shared/Components/Signature";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { Button, Card, Row, Col, Popconfirm, notification, Alert } from "antd";
import { useAuth } from "../../../contexts/AuthContext";
import FulfillmentStatus from "./FulfillmentStatus";
import { currencyFormatter } from "../../../utils/functions/numbers";
import { formatDate } from "../../../utils/functions/dates";
import PrintChecksModal from "./PrintChecksModal";
import apiCall from "../../../utils/functions/apiCall";

function OrderDetails() {
  const { fulfillmentId } = useParams();
  const [client, setClient] = useState(null);
  const [checks, setChecks] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [fulfillmentData, setFulfillmentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [isPrinting, setIsPrinting] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [isAllShipped, setIsAllShipped] = useState(false);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [isPDFModalVisible, setIsPDFModalVisible] = useState(false);
  const [template, setTemplate] = useState([]);

  const fetchAllData = async () => {
    try {
      setLoading(true);

      // Fetch all data in parallel and ensure it resolves
      await fetchOrderDetails();
    } catch (e) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
    fetchTemplate();
    fetchAccounts();
  }, []);

  // useEffect(() => {console.log('heres templates', template)}, [template]);
  // useEffect(() => {console.log('heres accounts', accounts)}, [accounts]);
  // useEffect(() => {console.log('heres checks', checks)}, [checks]);

  async function fetchOrderDetails() {
    const fulfillmentRef = doc(db, `fulfillmentOrders`, fulfillmentId);
    const snapshot = await getDoc(fulfillmentRef);

    const result = snapshot.data();
    if (result) {
      setFulfillmentData(result);
      const checks = result.checks;
      setChecks(checks);

      let printingStatus = false;
      let shippingStatus = false;
      let allShipped = true;

      if (checks && checks.length > 0) {
        checks.forEach((check) => {
          if (check?.substatus && check.substatus.includes("printed")) {
            printingStatus = true;
          }
          if (check?.substatus && check.substatus.includes("shipped")) {
            shippingStatus = true;
          } else {
            allShipped = false;
          }
        });
      }
      setIsPrinting(printingStatus);
      setIsShipping(shippingStatus);
      setIsAllShipped(allShipped);
    }

    return result;
  }

  const fetchTemplate = async () => {
    const TEMPLATE_STORAGE_KEY = "templateData";
    try {
      // Check if template is already stored in local storage
      const storedTemplate = sessionStorage.getItem(TEMPLATE_STORAGE_KEY);

      if (storedTemplate) {
        // If template is found in local storage, parse it and set it in state
        setTemplate(JSON.parse(storedTemplate));
      } else {
        // If not found in local storage, fetch it from Firebase
        const templatesCollection = collection(
          db,
          `clients/${user.clientId}/templates`
        );
        const templateSnapshot = await getDocs(templatesCollection);

        if (!templateSnapshot.empty) {
          const allTemplateData = templateSnapshot?.docs?.map((doc) =>
            doc.data()
          );

          // Store the fetched templates in local storage
          sessionStorage.setItem(
            TEMPLATE_STORAGE_KEY,
            JSON.stringify(allTemplateData)
          );

          // Set the fetched templates in state
          setTemplate(allTemplateData);
        }
      }
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "You don't have permission to fetch templates",
      });
    }
  };

  const fetchAccounts = async () => {
    const ACCOUNT_STORAGE_KEY = "encryptedAccountData";
    // const storedAccounts = sessionStorage.getItem(ACCOUNT_STORAGE_KEY);
    const storedAccounts = false;

    try {
      if (storedAccounts && storedAccounts.length > 0) {
        setAccounts(await JSON.parse(storedAccounts));
        // console.log('rehydrated accounts', accounts)
      } else {
        const accountsRef = collection(db, `clients/${user.clientId}/accounts`);
        const queryAccounts = query(
          accountsRef,
          where("isDeleted", "==", false)
        );

        const snapshot = await getDocs(queryAccounts);
        const encryptedData = snapshot?.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // console.log(encryptedData);
        sessionStorage.setItem(
          ACCOUNT_STORAGE_KEY,
          await JSON.stringify(encryptedData)
        );
        setAccounts(encryptedData);
        // console.log('account pulling data', accounts)
      }
    } catch (error) {
      notification.error("Error fetching accounts:");
      console.error("Error fetching accounts:", error);
    }
  };

  async function cancelFulfillment() {
    setLoading(true);
    const url = `/v1/fulfillment/${fulfillmentId}/cancel`;


    try {
      // Make the POST request using 
      // const response = await .post(url, {}, config);
      const response = await apiCall({
        url, // URL to send the POST request
        method: 'POST', 
    });      
    
      // return response.data;
      notification.success({ message: "The order has been canceled" });
    } catch (error) {
      console.error("There was a problem with the operation:", error);
      notification.error({ message: "Error canceling order. No changes made" });
    } finally {
      await fetchAllData();
      setLoading(false);
    }
  }

  async function completeFulfillment() {
    setLoading(true);
    const url = `/v1/fulfillment/${fulfillmentId}/complete`;

    try {
      // const response = await .post(url, body, config);

      const response = await apiCall({
        url, // URL to send the POST request
        method: 'POST', 
    });  
      // Return the data from the response
      // return response.data;
      notification.success({
        message:
          "The order has been completed and an email has been send to the client",
      });
    } catch (error) {
      console.error("There was a problem with the operation:", error);
      notification.error({
        message: "Error completing the order. No changes made",
      });
    } finally {
      await fetchAllData();
      setLoading(false);
    }
  }

  async function updateCheckToPrinted(checkId) {
    setLoading(true);
    const url = `/v1/fulfillment/${fulfillmentId}/${checkId}`;

    // Set the headers including the bearer token
  

    const body = {
      status: "printed",
    };

    try {
      // const response = await .patch(url, body, config);
      const response = await apiCall({
        url, // URL to send the POST request
        method: 'PATCH', 
        body: body, 
    });  
      // Return the data from the response
      // return response.data;
      // notification.success({
      //   message: "Check has been updated to printed",
      // });
    } catch (error) {
      console.error("There was a problem update the check as printed:", error);
      notification.error({
        message: "There was a problem update the check as printed",
      });
    } finally {
      setLoading(false);
      setIsPDFModalVisible(false);
    }
  }

  async function updateCheckToCanceled(checkId) {
    setLoading(true);
    const url = `/v1/fulfillment/${fulfillmentId}/${checkId}`;


    const body = {
      status: "canceled",
    };

    try {
      // const response = await .patch(url, body, config);
      const response = await apiCall({
        url, // URL to send the POST request
        method: 'PATCH', 
        body: body, 
    });  
      // Return the data from the response
      // return response.data;
      notification.success({
        message: "Check has been updated to printed",
      });
    } catch (error) {
      console.error("There was a problem update the check as printed:", error);
      notification.error({
        message: "There was a problem update the check as printed",
      });
    } finally {
      await fetchAllData();
      setLoading(false);
    }
  }

  async function updateCheckToShipped(checkId, carrier, trackingNumber) {
    setLoading(true);
    const url = `/v1/fulfillment/${fulfillmentId}/${checkId}`;



    const body = {
      status: "shipped",
      carrier,
      trackingNumber,
    };

    try {
      // const response = await .patch(url, body, config);
      const response = await apiCall({
        url, // URL to send the POST request
        method: 'PATCH', 
        body: body, 
    });  
      // Return the data from the response
      // return response.data;
      notification.success({
        message:
          "The order has been completed and an email has been send to the client",
      });
    } catch (error) {
      console.error("There was a problem with the operation:", error);
      notification.error({
        message: "Error completing the order. No changes made",
      });
    } finally {
      await fetchAllData();
      setLoading(false);
    }
  }

  const handlePrint = () => {
    setIsPrintModalVisible(false);
    setIsPDFModalVisible(true);
  };

  return (
    <>
      <h1 className="monospace">Fulfillment Order Details</h1>
      {/* <Row style={{ paddingBottom: 20 }}>
        <FulfillmentStatus fulfillmentOrder={checks} />
      </Row> */}
      {user?.role?.toLowerCase() === "employee" && isAllShipped && fulfillmentData?.status !== "canceled" &&(
        <Row style={{ marginBottom: 20 }}>
          <Col span={24}>
            <Alert
              message="All checks was shipped, click on complete order to notify the client"
              type="warning"
              showIcon
            />
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Card
            style={{ minHeight: "200px", maxHeight: 600, overflowY: "auto" }}
            title="Order Actions"
            type="inner"
            loading={loading}
          >
            <Row>
              <Col>
                <Button onClick={() => navigate(-1)} className="button-gray">
                  Back
                </Button>
              </Col>
              {fulfillmentData?.status !== "completed" &&
                fulfillmentData?.status !== "canceled" && (
                  <Col>
                    {" "}
                    <Popconfirm
                      title="Are you sure?"
                      description="Do you want to void the order? You will get full refund if check in 'Pending' status. If check is in printed status, a $2.50 per check will be charged. For checks already shipped, there will be no refunds. Would you like to proceed?"
                      onConfirm={cancelFulfillment}
                      okText="Yes"
                      okType="danger"
                      cancelText="No"
                    >
                      <Button style={{ marginLeft: 10 }} className="button-red">
                        Void Order
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              {user?.role?.toLowerCase() === "employee" && isAllShipped && fulfillmentData?.status !== "canceled" && (
                <Col>
                  {" "}
                  <Popconfirm
                    title="Are you sure?"
                    description="All checks must be in shipped status to complete the order"
                    onConfirm={completeFulfillment}
                    okText="Yes"
                    okType="danger"
                    cancelText="No"
                  >
                    {/* Only show compelte order to employees AND if shipping hasn't started */}

                    <Button style={{ marginLeft: 10 }} className="button-green">
                      Complete Order
                    </Button>
                  </Popconfirm>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              <Col></Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            style={{ minHeight: "200px", maxHeight: 600, overflowY: "auto" }}
            loading={loading}
            title="Order Details"
            type="inner"
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Date Created</strong>
                  </td>
                  <td>{formatDate(fulfillmentData?.createdAt)}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Carrier</strong>
                  </td>
                  <td>{fulfillmentData?.pricingInfo?.carrier}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Service Selected</strong>
                  </td>
                  <td>{fulfillmentData?.pricingInfo?.serviceName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Total Cost</strong>
                  </td>
                  <td>{`$${currencyFormatter(
                    fulfillmentData?.pricingInfo?.totalPrice
                  )}`}</td>
                </tr>
              </tbody>
            </table>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            style={{ minHeight: "200px", maxHeight: 600, overflowY: "auto" }}
            loading={loading}
            title="Fulfillent Status"
            type="inner"
          >
            <Col span={24}>
              {" "}
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Status: </strong>
                    </td>
                    <td
                      className={
                        fulfillmentData?.status === "completed"
                          ? "success"
                          : fulfillmentData?.status === "canceled"
                          ? "error"
                          : ""
                      }
                    >
                      {fulfillmentData?.status}
                    </td>{" "}
                  </tr>
                </tbody>
              </table>
            </Col>
          </Card>
        </Col>
        <Col span={24}>
          <Card
            style={{ minHeight: "300px", marginTop: 20 }}
            loading={loading}
            title="Checks"
            type="inner"
          >{user.role === 'employee' &&
            <Button onClick={() => setIsPrintModalVisible(true)}>
              Print Checks
            </Button>}
            <Checks
              user={user}
              checks={checks}
              fulfillmentId={fulfillmentId}
              updateCheckToPrinted={updateCheckToPrinted}
              updateCheckToShipped={updateCheckToShipped}
              updateCheckToCanceled={updateCheckToCanceled}
              setIsPrintModalVisible={setIsPrintModalVisible}
            />
          </Card>
        </Col>
      </Row>
      <PrintChecksModal
        isVisible={isPrintModalVisible}
        maskClosable={false}
        accounts={accounts}
        onOk={handlePrint}
        onCancel={() => setIsPrintModalVisible(false)}
        selectedChecksToPrint={checks}
        setSelectedChecksToPrint={setChecks}
        templates={template}
        isPDFModalVisible={isPDFModalVisible}
        setIsPDFModalVisible={setIsPDFModalVisible}
        handlePrintConfirm={updateCheckToPrinted}
        setLoading={setLoading}
        fetchAllData={fetchAllData}
      />
    </>
  );
}

export default OrderDetails;
